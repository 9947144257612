export default {
  lang: 'မြန်မာအက္ခရာ(緬甸文)',
  global: {
    game_type: {
      // cockfight: '',
      // spider: '',
      // color_game: '',
      // apple: '',
      // up_down: '',
    },
    fight_result: {
      meron: 'နဂါး',
      wala: 'ဖီးနစ်',
      draw: 'သရေ',
      draw2: 'သရေ',
      cancel: 'ပယ်ဖျက်',
      red: 'အနီ',
      blue: 'အပြာ',
      gold: 'ရွှေ',
      // white: '',
      // red3: '',
      // white3: '',
      // down: '',
      // up: '',
    },
    action: {
      yes: 'အတည်ပြုလုပ်ဆောင်မည်။',
      no: 'မလုပ်ဆောင်ပါ။',
      go: 'သွားပါ',
      search: 'ရှာဖွေသည်',
      reset: 'ပြန်လည်သတ်မှတ်ပါ',
      create: 'ဖန်တီးသည်',
      logout: 'ထွက်ပါ',
      submit: 'ပေးပို့မည်။',
      edit: 'ပြင်ဆင်',
      // risk_edit: '',
      lock_earning: 'ပေးချေမှု',
      decision_on_hold: 'ခေတ္တရပ်နားမည်',
      cancel_on_hold: 'ဆက်လက်ကစားမည်',
      not_allow_bet: 'လောင်းခွင့်မပြုပါ',
      allow_bet: 'လောင်းခွင့်ပြုသည်',
      next: 'နောက်တစ်ဆင့်',
      prev: 'အရင်တစ်ဆင့်',
      step: 'အဆင့်',
      ok: 'အတည်ပြု',
      collapse: 'စုဆောင်းသည်',
      mask_video: 'ဗီဒီယိုကိုဖုံးအုပ်မည်',
      cancel_mask_video: 'ဗီဒီယိုအဖုံးကိုပိတ်မည်',
      records: 'မှတ်တမ်း',
      cancel: 'ပယ်ဖျက်',
      export: 'မြန်မာ့ရောင်းမှု',
      select_file: 'ဖိုင်ကိုရွေးချယ်ပါ',
      upload_image: 'ပုံပို့ဆောင်ပါ',
      cancel_upload: 'ပုံပို့ဆောင်ခြင်းကိုပယ်ဖျက်ပါ',
      // update: '',
      // update_all: "",
      // upload_all_images: "",
      // cancel_update_all: "",
      refresh_fights: 'ပွဲချိန်များကို ပြန်လည်စတင်ပါ။',
      open_bet: 'အလောင်းအစားကိုလက်ခံပါ။',
      close_bet: 'လောင်းကြေးလက်မခံ',
      re_declare: 'အနိုင်ရသူကို ပြန်လည်ကြေငြာပါ။',
      locking: 'ပေးချေဆဲ',
      open_arena: 'ကြက်ဝိုင်းစတင်ဖွင့်လှစ်',
      close_arena: 'ပူးလိုင်းအောက်မှာရှိပြီးပွဲအားလုံးပိတ်ပါ',
      // refresh_cache: '',
      // cancel_all: '',
      delete: 'ဖျက်သိမ်း',
      monitor: 'မော်နီဗီဒီယိုကိုကြည့်ပါ',
      // monitor_video: '',
      // retry: '',
    },
    common: {
      account: 'အကောင့်',
      account_id: 'အမှတ်စဉ်',
      active_status_list: {
        all: 'အားလုံး',
        active: 'ဖွင့်သည်',
        close: 'ပိတ်သည်',
        // enable: '',
        // disable: ''
      },
      action: 'လုပ်ဆောင်သည်',
      // add_settle_bet_info: '',
      agent: 'ကိုယ်စားလှယ်',
      agent_account: 'ကိုယ်စားလှယ်အကောင့်',
      agent_count: 'ကိုယ်စားလှယ်အရေအတွက်',
      arena: 'ကွင်း',
      arena_suspend: 'ကြက်ဝိုင်းခဏရပ်ထားသည်',
      all: 'အားလုံး',
      amount: 'ငွေကြေးပမာဏ',
      announcement: 'ကြေညာချက်',
      average_percentage: 'ပျှမ်းမျှလောင်းကြေး',
      bet_amount: 'လောင်းကြေးပမာဏ',
      bet_count: 'အလောင်းစားအရေအတွက်',
      bet_type_list: {
        all: 'အားလုံး',
        deposit: 'အပ်ငွေ',
        withdraw: 'ထုတ်ငွေ',
        OrderDeposit: 'အပ်ငွေ',
        OrderWithdraw: 'ထုတ်ငွေ',
        bet: 'လောင်းပါ',
        settle: 'စာရင်းချုပ်',
        cancel: 'ပယ်ဖျက်',
        resettle: 'ပြန်လည်စာရင်းချုပ်',
        fail: 'မအောင်မြင်',
        init: 'ကန ဦး',
      },
      // business_status: '',
      // calculation_base: '',
      // callback_url: '',
      change_password: 'လျို့၀ှက်နံပါတ်ပြောင်းမည်',
      confirm: 'အတည်ပြုပါ',
      confirm_password: 'လျှို့ဝှက်နံပါတ်ကိုအတည်ပြုပါ',
      create_agent: 'အသစ်တိုးကိုယ်စားလှယ်',
      // create_setting: '',
      created_at: 'တည်ဆောက်ချိန်',
      currency: 'ငွေကြေးအမျိုးအစား',
      // current_points: '',
      // custom_logo: '',
      // custom_logo_list: {
      //   odin: '',
      //   gleague: '',
      //   fiesta: '',
      //   fiestafruit: '',
      // },
      date: 'နေ့ရက်',
      date_type: 'အချိန်ရွေးချယ်မှု',
      date_type_list: {
        BetAt: 'လောင်းကစားအချိန်',
        GameDate: 'ပြိုင်ပွဲနေ့ရက်',
        SettleAt: 'စာရင်းချုပ်ချိန်',
        OpenRound: 'ပွဲစတင်ချိန်',
      },
      declare_fight_winner: 'အနိုင်ရရှိသူမှာ',
      defaultLanguage: 'မူရင်းဘာသာစကား',
      description: 'ဖော်ပြချက်',
      email: 'အီးမေးလ်',
      fight_count: 'ပွဲစဉ်အရေအတွက်',
      fight_id: 'ပွဲကိုယ်စားပြုကုဒ်',
      game_date: 'ပြိုင်ပွဲနေ့ရက်',
      // game_type: '',
      // ggr: '',
      ip: 'IP',
      id: 'အမှတ်စဉ်',
      kill_rate: 'သတ်နှုန်း',
      language: 'ဘာသာစကား',
      last_login: 'နောက်ဆုံးအကြိမ်လော့အင်ဝင်',
      // link_master: "",
      lock_earning: 'ပေးချေမှု',
      log: 'မှတ်တမ်း',
      // main_arena: '',
      // main_pool: '',
      manager: 'စီမံခန့်ခွဲသူ',
      master: 'ကိုယ်စားလှယ်ချုပ်',
      max: 'အများဆုံးတန်ဖိုး',
      merchant: 'လုပ်ငန်း/ဆိုင်လုပ်ငန်း/ဆိုင်အမှတ်',
      merchant_account: 'လုပ်ငန်း/ဆိုင်အကောင့်',
      meron_wala: 'ဖီးနစ် နဂါး',
      min: 'အနည်းဆုံးတန်ဖိုး',
      month: 'လ',
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: 'အမည်',
      // none: '',
      odd: 'လျှော်နှုန်း',
      odds_type: 'အလျော်အမျိုးအစား',
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: 'လုပ်ဆောင်သူ',
      // options: '',
      // order_num: "",
      order_status_list: {
        init: 'အော်ဒါလက်ခံရရှိသည်',
        process: 'လုပ်ဆောက်ဆဲ',
        success: 'အောင်မြင်သည်',
        failed: 'မအောင်မြင်',
        pending: 'လက်၀ယ်ထားရှိသည်',
        fail: 'မအောင်မြင်',
        error: 'အမှား',
      },
      password: 'လျှို့ဝှက်နံပါတ်',
      payout: 'လျှော်နှုန်း',
      platform_profit: 'ဂိမ်းပလက်ဖောင်းနည်းပညာ၏အမြတ်',
      player_account: 'ကစားသမားအကောင့်',
      // player_name: "",
      player_bet_limit: 'ကစားသမားတစ်ကြိမ်ခြင်းစီလောင်းကြေးကန့်သတ်',
      player_count: 'ကစားသမားအရေအတွက်',
      player_round_limit: 'ကစားသမားပွဲစဉ်လောင်းကြေးကန့်သတ်',
      // pool_status: '',
      profile: 'ကိုယ်စားလှယ်အချက်အလက်',
      provider_name: 'ပံ့ပိုးသူအမည်',
      rate: 'စုပ်ယူငွေ (%)',
      rate_amount: 'စုစုပေါင်းစုပ်ယူသောငွေပမာဏ',
      risk_odd: 'ဂိမ်းအလျော်နှုန်း',
      role: 'အခန်းကဏ္ဌ',
      // risk_control: '',
      // risk_type_list:{
      //   bet_limit: '',
      // no_login: '',
      // },
      round_id: 'ပြိုင်ပွဲနံပါတ်',
      round_limit: 'အမြင့်ဆုံးပွဲစဉ်လောင်းကြေး',
      // round_status: '',
      round_status_list: {
        idle: 'Idle',
        open: 'လောင်းလို့ရသည်',
        close: 'အလောင်းအစားပြုလုပ်ခြင်းကိုတားမြစ်သည်',
        locked: 'စာရင်းချုပ်ပြီး',
        last_call: 'နောက်ဆုံးလောင်းကြေး',
        // arena_close: "",
        unsettled: 'မဖြေရှင်းရသေးသော',
        settled: 'ဖြေရှင်းပြီးသော',
      },
      status: 'အနေအထား',
      // setting: '',
      settled_at: 'စာရင်းချုပ်ချိန်',
      // settle_zero: '',
      side: 'မှလောင်းသည်',
      // skin: '',
      // skin_list: {
      //   default: '',
      //   brown: '',
      //   white: '',
      //   black: '',
      //   green: '',
      //   dark_and_light: '',
      // },
      slug: 'မဂဏန်း',
      theme: 'ရှေ့မျက်နှာစာအပြင်အဆင်',
      // top_agent: '',
      total: 'စုစုပေါင်း',
      total_bet_amount: 'စုစုပေါင်းလောင်းကြေးပမာဏ',
      total_bet_count: 'စုစုပေါင်းလောင်းကြေးအရေအတွက်',
      total_platform_profit: 'ဂိမ်းပလက်ဖောင်းနည်းပညာ၏စုစုပေါင်းအမြတ်',
      total_rate_amount: 'စုစုပေါင်းစုပ်ယူသောငွေပမာဏ',
      total_user_bet: 'စုစုပေါင်းလောင်းသူ',
      total_valid_bet_amount: 'စုစုပေါင်းထိရောက်သောလောင်းကစား',
      type: 'အမျိုးအစား',
      // update_setting: '',
      updated_at: 'Updateအချိန်',
      usable_language: 'အသုံးပြုနိုင်သောဘာသာစကား',
      user: 'အသုံပြုသူ',
      user_count: 'လောင်းသူအဖွဲ့ဝင်အရေအတွက်',
      video_url: 'ဗီဒီယိုအရင်းအမြစ် Url',
      // icp_video_url: "",
      valid_amount: 'အကျုံးဝင်လောင်းကြေး',
      winner: 'ရလဒ်များ',
    },
    times: {
      unset: 'သတ်မှတ်မထားပါ',
      today: 'ယနေ့',
      yesterday: 'မနက်ဖြန်',
      this_week: 'ယခုတစ်ပတ်',
      last_week: 'ပြီးခဲ့သောရက်သတ္တပတ်',
      this_month: 'ယခုလ',
      last_month: 'ပြီးခဲ့သောလ',
      custom: 'စိတ်ကြိုက်',
      tooltip: 'မှတ်တမ်းထိန်းသိမ်း- ၃လ(ယနေ့ရက်စွဲ-ရက်၁၀၀)',
    },
    menu: {
      group: {
        player: 'ကစားသမား',
        record: 'မှတ်တမ်း',
        manager: 'စီမံခန့်ခွဲသူ',
        control: 'ထိန်းချုပ်ရာနေရာ',
        Agent: 'ကိုယ်စားလှယ်',
        MasterAgent: 'မာစတာကိုယ်စားလှယ်',
        ArenaModerator: 'ကြက်ဝိုင်း',
        // Pool: ''
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: 'ထိန်းချုပ်ရာနေရာ',
      CurrentArena: 'လက်ရှိအလောင်းအစား',
      Players: 'ကစားသမား စီမံခန့်ခွဲမှု',
      PlayerCredits: 'ကစားသမားခရက်ဒစ်များ',
      BetLog: 'လောင်းကစားမှတ်တမ်း',
      GameResults: 'ကစားပွဲရလဒ်များ',
      Order: 'ငွေလွှဲပြောင်းမှတ်တမ်း',
      AgentList: 'ကိုယ်စားလှယ်စာရင်း',
      AgentSource: 'မာစတာကိုယ်စားလှယ်စုစုပေါင်းစာရင်း',
      AdminAgents: 'ကိုယ်စားလှယ်',
      AgentShow: 'ကိုယ်စားလှယ်အချက်အလက်',
      AgentRoles: 'ကိုယ်စားလှယ်ခွင့်ပြုချက်',
      AgentSubAccount: 'မန်နေဂျာ',
      MasterAgentList: 'မာစတာကိုယ်စားလှယ်စာရင်း',
      MasterAgentSource: 'မာစတာကိုယ်စားလှယ်စုစုပေါင်းစာရင်း',
      Merchants: 'လုပ်ငန်း/ဆိုင်အမည်စီမံခန့်ခွဲမှု',
      MerchantRoles: 'လုပ်ငန်း/ဆိုင်ဇတ်ကောင်စီမံခန့်ခွဲမှု',
      MerchantAdmin: 'လုပ်ငန်း/ဆိုင်အကောင့်စီမံခန့်ခွဲမှု',
      MerchantIpList: 'IP ကြင်နှစ်သားအကျဉ်းသတ်များ',
      ChangePassword: 'လျို့၀ှက်နံပါတ်ပြောင်းမည်',
      GGR: 'GGRအစီရင်ခံစာ',
      Profile: 'ပရိုဖိုင်',
      VideoSource: 'ဗီဒီယိုအရင်းအမြစ်',
      Transfer: 'ငွေသွင်းငွေထုတ်စုံစမ်းမှု',
      MonthlyProfit: 'လစဉ်အမြတ်အစွန်းဇယား',
      // DailyBettingStatistic: "",
      ExchangeRate: 'ငွေလွှဲနှုန်းစီမံခန့်ခွဲမှု',
      CreateAgent: 'အသစ်တိုးကိုယ်စားလှယ်',
      // PoolSetting: '',
      // MalayOddsSetting: '',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: 'ကျေးဇူးပြု၍{value}ရိုက်သွင်းပါ',
      select_value: 'ကျေးဇူးပြု၍{value}ကိုရွေးပါ',
      must_be_at_4_characters: '{value}အနည်းဆုံးစာလုံး၄လုံးထည့်သွင်းပါ',
      must_be_at_6_characters: '{value}အနည်းဆုံးစာလုံး၆လုံးထည့်သွင်းပါ',
      must_be_between_characters: '{value}သည်{min}နှင့်{max}စာလုံးကြားရှိရမည်',
      must_be_between_number: '{value}သည်{min}နှင့်{max}ကြားရှိရမည်',
      must_be_alphanumeric: '{value}သည်အင်္ဂလိပ်အက္ခရာ(သို့)ဂဏန်းများဖြစ်ရမည်',
      must_be_number: 'ကိန်းဂဏန်းနံပါတ်တစ်ခုဖြစ်ရမည်',
      must_be_number_value: '{value}သည်ဂဏန်းဖြစ်ရမည်',
      two_no_match: 'INPUTS နှစ်ခု၏အကြောင်းအရာများကွဲပြားနေသည်',
      failed_to_create: '{value}ဖန်တီးမှုမအောင်မြင်ပါ',
      failed_to_edit: '{value}တည်းဖြတ်ခြင်းမအောင်မြင်ပါ',
      success_to_create: '{value}ဖန်တီးမှုအောင်မြင်ပါသည်',
      success_to_edit: '{value}တည်းဖြတ်ခြင်းအောင်မြင်ပါသည်',
      success_to_change: '{value}ပြင်ဆင်ခြင်းအောင်မြင်ပါသည်',
      // failed_to_update: "",
      decision_on_hold: 'အပေါ်ခလုပ်ကိုနှိပ်ပါ၊ ကြက်တိုက်ခြင်းအလောင်းအစားနှင့်ငွေလွှဲ',
      cancel_on_hold: 'အပေါ်ခလုပ်ကိုနှိပ်ပါ ။ကြက်တိုက်ခြင်းကစားနည်းစတင်မည်',
      close_bet: 'အပေါ်ခလုပ်ကိုနှိပ်ပါ။ကြက်တိုက်ခြင်းအလောင်းအစားခေတ္တရပ်ဆိုင်းမည်',
      open_bet: 'အပေါ်ခလုပ်ကိုနှိပ်ပါ။ကြက်တိုက်ခြင်းလောင်းရန်ပြန်လည်ဖွင့်လှစ်မည်',
      mask_video: 'အပေါ်ခလုပ်ကိုနှိပ်ပါ ။ရှေ့မျက်နှာစာက ဗီဒီယိုကိုဖုံးကွယ်ပေးပါလိမ့်မည်',
      cancel_mask_video: 'အပေါ်ခလုပ်ကိုနှိပ်ပါ ။ရှေ့မျက်နှာစာက ဗီဒီယိုဖုံးကွယ်မှုကိုဖွင့်ပေးပါလိမ့်မည်',
      delete: '{value}ဖျက်သိမ်းမည်?',
      choose_arena: 'ကြက်ပွဲရွေးပါ',
      enter_fight_number: 'ပွဲစဉ်နံပါတ်ရိုက်သွင်းပါ',
      select_date: 'ကျေးဇူးပြု၍နေ့စွဲရွေးပါ',
      select_one: 'ကျေးဇူးပြု၍အနည်းဆုံး {value} တစ်ခုရွေးပါ',
      browser_not_supported: 'ဤဘရောက်ဆာကိုမပံ့ပိုးပါ။ google chrome safari ဖွင့်၍okခလုပ်ကိုနှိပ်ပါ',
      network_error_title: 'အင်တာနက် မှားယွင်းမှု',
      network_error_info: 'ကျေးဇူးပြု၍ သင်အင်တာနက်ကို အတည်ပြုပြီး စာမျက်နှာပြင်ကို ရီဖရှက် လုပ်ပါ။',
      must_be_greater_than_zero: 'ပမာဏသည် သုညထက်ကြီးသော (သို့)ညီမျှသောဂဏန်းဖြစ်ရမည်။',
      must_be_greater_than_min: 'အများဆုံးပမာဏသည်အနဲဆုံးပမာဏနှင့်ညီတူ(သို့)ထက်ကြီးရပါမည်',
      incorrect_ip: 'မသေချာသောIP format',
      criteria_be_selected: 'ရှာဖွေမှုစံနှုန်းတစ်ခုကိုရွေးချယ်ပါ',
      success_to_delete: '{value}ဖျက်သိမ်းခြင်းအောင်မြင်ပါသည်',
      failed_to_delete: '{value}ဖျက်သိမ်းခြင်းမအောင်မြင်ပါ',
      // failed_to_delete_with_reason: "",
      account_placeholder: 'ကျေးဇူးပြု၍4-16အင်္ဂလိပ်၊ ဂဏန်းကိုရိုက်သွင်းပါ',
      password_placeholder: 'ကျေးဇူးပြု၍6-20အင်္ဂလိပ်၊ ဂဏန်း၊သင်္ကေတ ကိုရိုက်သွင်းပါ',
      confirm_password_placeholder: 'တူညီသောလျှို့ဝှက်နံပါတ်ထပ်မံအတည်ပြုပါ',
      date_type_hint:
        'အစီရင်ခံစာသည် "{dateType}" ကို အခြေခံထားပြီး နေ့စဉ် နံနက် 10:00 (GMT+8) တွင် မနေ့ပွဲစဉ်ကိုတွက်ပေးပါသည်။ ယနေ့၏ဒေတာကို မပါဝင်သေးပါ။',
      please_create_role: 'စီမံခန့်ခွဲသူကို မဖန်တီးမီ၊ ကျေးဇူးပြု၍ ဇတ်ကောင်တစ်ခုကိုအရင် ဖန်တီးပါ။',
      // max_length: '',
      // whole_url: '',
      setting_player_round_max: 'ကစားသမားပွဲစဉ်တိုင်းအများဆုံးနှင့်အနည်းဆုံးလောင်းကြေးကန့်သတ်',
      setting_player_bet_max: 'ကစားသမားတစ်ကြိမ်ခြင်းစီ၏အမြင့်ဆုံးလောင်းကြေးတန်ဖိုးကန့်သတ်',
      // A_greater_tham_B: '',
      // reset_data: ''
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    },
  },
  AgentsPage: {
    master_account: 'အထွေထွေကိုယ်စားလှယ်အကောင့်',
    languageList: 'ဘာသာစကားစာရင်း',
    create_master_agent: 'အသစ်တိုးကိုယ်စားလှယ်ချုပ်',
    rate: 'စုပ်ယူငွေ (%)',
    rate_note:
      'အောက်လိုင်းကိုယ်စားလှယ်၏ ကော်မရှင်ခသည်အပေါ်လိုင်းကိုယ်စားလှယ်ထက် ပိုများပါက၊ စနစ်သည် ၎င်းတို့အားတပြိုင်နက်တည်း ကော်မရှင်အသစ်အဖြစ်လျှော့ချပေးမည်ဖြစ်သည်။',
    max_with_value: 'အများဆုံးတန်ဖိုး {{value}}',
    abbr: 'အတိုချုပ်ရေး',
    abbr_placeholder: 'ကျေးဇူးပြု၍1-6အင်္ဂလိပ်၊ ဂဏန်းကိုရိုက်သွင်းပါ',
    edit_agent: 'အယ်ဒီတာကိုယ်စားလှယ်',
    // agent_credit_points_options: '',
  },
  AgentSubAccountPage: {
    authority: 'ခွင့်ပြုချက်',
    authority_list: {
      read: 'ဖတ်ပါ',
      editor: 'ပြင်ဆင်',
    },
    create_sub_account: 'စီမံခန့်ခွဲသူအသစ်ထည့်ပါ',
  },
  BetLogPage: {
    win_lose: 'အနိုင်အရှုံး',
    bet_return: 'ပြန်အမ်းငွေပမာဏ',
    is_settled: 'စာရင်းချုပ်ပြီးပြီလား',
    device: 'စက်ကရိယာ',
    amount_type: 'ငွေကြေးပမာဏအမျိုးအစား',
    amount_range: 'ငွေကြေးပမာဏအပိုင်းအခြား',
  },
  ChangePasswordPage: {
    current_password: 'လက်ရှိလျှို့ဝှက်နံပါတ်',
    current_password_placeholder: 'လက်ရှိလျှို့ဝှက်နံပါတ်ကိုရိုက်သွင်းပါ',
    new_password: 'လျှို့ဝှက်နံပါတ်သစ်',
  },
  ArenaModeratorPage: {
    video_rate: 'ဗီဒီယိုအရင်းအမြစ် စုပ်ယူငွေ',
    online_users: 'အွန်လိုင်းအသုံးပြုသူများ',
  },
  ControlPanelPage: {
    arena_on: 'ဖွင့်',
    arena_off: 'ပိတ်',
    no_announce: 'ကြေငြာခြင်းမရှိသေးပါ၊ ထည့်သွင်းရန် နှင့်တည်းဖြတ်ရန်နှပ်ပါ။',
    edit_announce: 'ကြေညာချက်ပြင်ဆင်',
    open_arena_title: 'ကြက်ဝိုင်းဝိုင်းစတင်ဖွင့်လှစ်မည်?',
    close_arena_title: 'ယနေ့ကြက်ဝိုင်းပိတ်ရန်သေချာပါလား?(တိုက်ပွဲအားလုံးပြီးဆုံးပါမည်)',
    start_fight: 'ဂိမ်းစတင်သည့်ကိုယ်စားကုဒ်နံပါတ်',
    start_time: 'စတင်ချိန်',
    open_betting: 'အလောင်းအစားများကို လက်ခံပါသလား?',
    close_betting: 'အလောင်းအစားကို ပိတ်မလား?',
    re_declare_title: 'အနိုင်ရသူကို ပြန်ကြေညာမလား?',
    re_declare_content: 'အနိုင်ရသူကို ပြန်လည်ကြေငြာပါ၊ ပွဲကိုယ်စားပြုကုဒ် {id} သည် အနိုင်ရရှိသူဖြစ်သည်',
    lock_earing_title: 'ပေးချေမှု?',
    lock_earing_content: 'ပေးချေမှု၊ ပွဲကိုယ်စားပြုကုဒ် {id}',
    click_again_to: 'ကိုနှိပ်ပါ။',
    file_upload: 'ဖိုင်ကိုတင်ပါ။',
    arena_cover: 'ဂိမ်းဝင်ပေါက်မြေပုံ',
    mask_file: 'မျက်နှာဖုံးမြေပုံ',
    schedule: 'ဂိမ်းအချိန်ဇယား',
    // edit_provider_url: "",
    img_path: 'ဖိုင်လမ်းကြောင်း',
    below_px_value: '{value}px အောက်ပါ',
    img_format: 'ပုံစံ',
    img_recommended_size: 'အကြံပြုထားသောအရွယ်အစား',
    file_size: 'ဖိုင်အရွယ်အစား',
    image: 'ပုံ',
    file_no_selected: 'ဖိုင်များကို ရွေးချယ်ထားခြင်းမရှိပါ။',
    no_image: 'ပုံမရှိပါ။',
    // edit_video_url: "",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: 'ကျေးဇူးပြု၍ စတင်နံပါတ်ကို ထည့်ပါ။',
      max_image_size: 'အများဆုံးပုံအရွယ်အစားမှာ {value}kb ဖြစ်သည်။',
      error_image_type: 'ပုံဖော်မတ်မှားသည်၊ - jpg၊ jpeg၊ png သာလက်ခံသည်။',
      delete_image_placeholder: '{value} ကို ဖျက်လိုသည်မှာ သေချာပါသလား?',
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    },
  },
  CurrentArenaPage: {
    count: 'အရေအတွက်',
    unsettlement: 'စာရင်းမချုပ်ရသေး',
    fight_action: 'ပွဲလည်ပတ်မှု',
  },
  GameResultsPage: {
    open_bet_at: 'လောင်းကြေးလက်ခံမှု စတင်ချိန်',
    close_bet_at: 'လောင်းကြေးလက်ခံမှုနောက်ဆုံးအချိန်',
    declare_at: 'ရလဒ်ကြေညာချိန်',
    reload_at: 'အချိန်ပြန်လည်သတ်မှတ်ပါ',
    cancel_at: 'ပယ်ဖျက်ချိန်',
    total_rate: 'စုစုပေါင်းစုပ်ယူသောငွေ',
    total_meron: 'နဂါး စုစုပေါင်းလောင်းကြေး',
    total_wala: 'ဖီးနစ် စုစုပေါင်းလောင်းကြေး',
    total_draw: 'သရေ စုစုပေါင်းလောင်းကြေး',
    status_list: {
      initial: 'ကန ဦး',
      betting: 'လောင်းဆဲ',
      fighting: 'တိုက်ဆဲ',
      finished: 'စာရင်းချုပ်ဆဲ',
      reloaded: 'ပြန်လည်အဆုံးအဖြတ်ပေးခြင်း',
      earn_locked: 'ပေးချေမှု',
      last_call: 'နောက်ဆုံးလောင်းကြေး',
      cancel: 'ပယ်ဖျက်',
    },
    time: 'အချိန်',
    // reason: "",
    cancel_reasons: {
      // '3000': "",
      // '3001': "",
      // canceled_manual: "",
      // canceled_ws: ""
    },
    open_round: 'ပွဲစတင်ချိန်',
  },
  LoginPage: {
    log_in: 'လော့အင်ဝင်သည်',
    otp: 'OTP dynamic လျှို့ဝှက်နံပါတ်',
    message: {
      success: 'အဝင်အောင်မြင်ပါသည်',
      failed: 'အကောင့် (သို့ )လျှို့ဝှက်နံပါတ်မှားနေသည်',
    },
  },
  MerchantsPage: {
    admin: 'မန်နေဂျာစီမံခန့်ခွဲသူ',
    role: 'ဇတ်ကောင်စီမံခန့်ခွဲမှု',
    create_merchant: 'အသစ်တိုးလုပ်ငန်း/ဆိုင်အမှတ်',
    name: 'လုပ်ငန်း/ဆိုင်အမည်',
    message: {
      // master_agent_only_select_one: ""
    },
  },
  MerchantRolesPage: {
    // current_arena: '',
    can_permission_pages: 'အသုံးပြုနိုင်သောစာမျက်နှာ',
    use_account: 'အသုံးပြုသောအကောင့်',
    create_role: 'ဇတ်ကောင်ဇတ်ကောင်တစ်ခုဖန်တီးခြင်း။',
    edit_role: 'ဇတ်ကောင်ဇတ်ကောင်တည်းဖြတ်',
    role_name: 'ဇတ်ကောင်ဇတ်ကောင်အမည်',
    color: 'အရောင်',
    can_use_pages: 'အသုံးပြုနိုင်သောစာမျက်နှာ',
    arena_on_off: 'ကြက်ဝိုင်းပိတ်သည်',
    fight_control: 'ဂိမ်းအရေအတွက်ထိန်းချုပ်',
    lock_earning: 'ပေးချေမှု',
    announcement: 'ကြေညာချက်',
    decision_on_hold: 'ပြိုင်ပွဲရပ်တန့်',
    monitor: 'အခြေခံပြုပြင်ခြင်း',
    no_bets_allowed: 'လောင်းခွင့်မပြုပါ',
    mask_video: 'ဗီဒီယိုအုပ်ထားသည်',
    read: 'ဖတ်ပါ',
    update: 'အပ်ဒိတ်',
    create: 'ဖန်တီးသည်',
    delete: 'ဖျက်သိမ်း',
    edit: 'ပြင်ဆင်',
    dashboard_card: 'စုစုပေါင်းပမာဏကိုဖော်ြပပါ',
    confirm: 'အတည်ပြုပါ',
    approve: 'ခွင့်ပြုသည်',
    arena_cover: 'ဂိမ်းဝင်ပေါက်မြေပုံ',
    mask_file: 'မျက်နှာဖုံးမြေပုံ',
    schedule: 'ဂိမ်းအချိန်ဇယား',
    export: 'မြန်မာ့ရောင်းမှု',
    // edit_provider_url: "",
    // display_rate: "",
    // view_schedule: '',
    // view_log: '',
    account_to_use: 'ဤဇတ်ကောင်ကို အသုံးပြုသည့် အကောင့်များ ရှိပါသေးသည်။',
    // risk_edit: '',
    monitor_video: 'ဗီဒီယိုအခြေခံပြုပြင်ခြင်း',
  },
  MerchantAdminPage: {
    edit_manager: 'စီမံခန့်ခွဲသူကိုတည်းဖြတ်ပါ',
    create_manager: 'စီမံခန့်ခွဲသူဖန်တီးပါ',
  },
  MerchantIpPage: {
    master_agent: 'မာစတာကိုယ်စားလှယ် (သို့) ကိုယ်စားလှယ်',
    create_ip: 'IPအသစ်ထည့်ပါ',
  },
  DailyBettingStatisticPage: {
    // yesterday_percentage: "",
    // last_week_percentage: "",
    player_count: 'လောင်းသူအဖွဲ့ဝင်အရေအတွက်',
    // ave_per_fight_percentage: "",
    // last_week_ave_per_fight_percentage: ""
  },
  OrderPage: {
    after_balance: 'လွှဲပြောင်းပြီးနောက်ကျန်ငွေ',
  },
  PlayersPage: {
    balance: 'လက်ကျန်ငွေ',
    fee: 'ကိုင်တွယ်ကြေး',
    current_sign_in_ip: 'IPအကောင့်ဝင်ပါ',
    total_deposit: 'စုစုပေါင်းစုငွေ',
    total_withdraw: 'စုစုပေါင်းထုတ်ငွေ',
    total_win_lose: 'စုစုပေါင်းအရှုံးအနိုင်',
  },
  PlayerCreditsPage: {
    id: 'ငွေပေးငွေယူအမှတ်စဉ်နံပါတ်',
    type: {
      deposit: 'အပ်ငွေ',
      withdraw: 'ထုတ်ငွေ',
      settle: 'နိုင်',
      cancel: 'ပယ်ဖျက်',
      bet: 'လောင်းပါ',
    },
    before_amount: 'လုပ်ဆောင်ချက်မစမီငွေပမာဏ',
    after_amount: 'လုပ်ဆောင်ချက်စပြီးနောက်ငွေပမာဏ',
  },
  ProfilePage: {
    // setting_round_draw_max: "ပွဲစဉ်တိုင်း၏အမြင့်ဆုံးလောင်းကြေးကန့်သတ်",
    setting_round_draw_max_part1: 'ပွဲစဉ်သတ်မှတ်',
    setting_round_draw_max_part2: 'လောင်းကြေးကန့်သတ်ငွေပမာဏ',
    player_limit: 'ကစားသမားပွဲစဉ်လောင်းကြေးကန့်သတ်',
    setting_player_max: 'ကစားသမားပွဲစဉ်တိုင်းအများဆုံးနှင့်အနည်းဆုံးလောင်းကြေးကန့်သတ်',
    basic_info_title: 'အခြေခံအချက်အလက်',
    basic_info_text: 'သင့်ကိုယ်စားလှယ်၏ အခြေခံအချက်အလက်',
    // integration_info: '',
    // auth: '',
    // secret: '',
    // api_doc_url: '',
    // api_url: '',
    // video_traffic: '',
    // remain_video_data: '',
    // update_video_data: '',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
    // warning_no_enough_point: '',
    // please_decrease_own_points_to_zero: '',
    // please_remove_all_designated_agents: '',
    // please_confirm_all_agents_settled: '',
  },
  VideoSourcePage: {
    source: 'ဗီဒီယိုအရင်းအမြစ်',
    agent_source: 'ကိုယ်စားလှယ်ချုပ်ဗီဒီယိုအရင်းအမြစ်',
    accessable_merchants: 'အသုံးပြုနိုင်သောဆိုင်အကောင့်',
  },
  TransferPage: {
    slug: 'ငွေပေးငွေယူအမှတ်စဉ်နံပါတ်',
    data: 'အချက်အလက်',
    res: 'တုံ့ပြန်ရန်',
    round_id: 'ပတ်လည်',
    setting_master_id: 'တည်ဆောက်သည်',
    num: 'မဂဏန်း',
    resend: 'ပြန်ပို့ပါ',
    bet_num: 'မဂဏန်း',
    resend_note: 'မှတ်ချက်',
    resend_mark: 'အမှတ်အသား',
    message: {
      success_to_resend: 'ပြန်လည်ပေးပို့ခြင်းအောင်မြင်သည်။ No:{value}',
      fail_to_resend: 'ပြန်လည်ပေးပို့ခြင်းမအောင်မြင်ပါ။ No:{value}',
      success_to_remark: 'မှတ်ချက်အောင်မြင်သည်။ No:{value}',
      fail_to_remark: 'မှတ်ချက်မအောင်မြင်ပါ။ No:{value}',
    },
  },
  ExchangeRatePage: {
    currency: 'ငွေကြေးအမျိုးအစားID',
    currency_name: 'ငွေကြေး၏အမျိုးအစား',
    last_approved_rate: 'ယခင်ငွေလွှဲနှုန်း',
    today_rate: 'ယနေ့ငွေလွှဲနှုန်း',
    ref_rate: 'ကိုးကားငွေလွှဲနှုန်း',
    ref_rate_time: 'ငွေလွှဲနှုန်းကိုးကားရန်အချိန်',
    tbd_confirm_rate: 'ငွေလွှဲနှုန်းအတည်ပြုပါ',
    tbd_modify_rate: 'ငွေလွှဲနှုန်းပြုပြင်မည်',
    record: 'အပြောင်းအလဲမှတ်တမ်း',
    current_time: 'လက်ရှိအချိန်',
    last_approved_time: 'နောက်ဆုံးအတည်ပြုချိန်',
    base_currency: 'အခြေခံငွေကြေး',
    fetch_exchange_rate: 'ငွေလွှဲနှုန်းကိုရယူပါ',
    approve_rate: 'အတည်ပြုပြီးငွေလွှဲနှုန်း',
    rate_warning: 'သတိ*ခွင့်ပြုအတည်ပြုပြီးပါကငွေလွှဲနှုန်းသည်ချက်ချင်းအကျိုးသက်ရောက်မည်!',
    log: 'ငွေလွှဲနှုန်းပြောင်းလဲမှုမှတ်တမ်း',
    confirmed_by: 'အတည်ပြုသူ',
    confirmed_time: 'အတည်ပြုချိန်',
    approved_by: 'ခွင့်ပြုသူ',
    approved_time: 'ခွင့်ပြုချိန်',
    exchange_rate: 'ငွေလွှဲနှုန်း',
  },
  CreateAgentPage: {
    // parent_agent: '',
    // environment: '',
    // staging: '',
    // production: '',
    // brand: '',
    // trade_mode: '',
    // transfer_wallet: '',
    // single_wallet: '',
    // options: '',
    // level_sub_agent_with_value: '',
    // delete_agent_with_value: '',
    // add_agent_with_value: '',
    // agent_info: '',
    // designated_agent: '',
    message: {
      // select_master_first: '',
      // agent_same_master: ''
      // please_refresh_cache: '',
      // log_saved_time: '',
      // success_refresh_cache: '',
      // fail_refresh_cache: '',
    },
  },
  PoolSettingPage: {
    // pool_name: '',
    // odds_refresh_frequency: '',
    // last_call_percentage: '',
    // close_bet_percentage: '',
    // per_bet: '',
    // per_round: '',
    // risk_threshold: '',
    // current_schedule: '',
    // new_schedule: '',
    // update_schedule: '',
    // schedule: '',
    // view: '',
    // start_time: '',
    // end_time: '',
    // pool_configuration_options: '',
    // expect_date: '',
    // expect_time: '',
    // add_new_pool_configuration: '',
    // exceed_time: '',
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: '',
    // change_agent: '',
    // view_complete_record: '',
    // point_usage: '',
    // points_deduction: '',
    // positive_numbers: '',
    // negative_numbers: '',
    // points_addition: '',
  },
}
