<template>
    <div class="alarm-quota">
        <div class="alarm-quota-header">
            <div class="alarm-quota-title">
                <p>{{ $t('global.menu.AgentCreditPoints') }} - {{ $t('ProfilePage.alarm_quota') }}</p>
                <a-button v-if="updatePermission" type="primary" icon="edit" @click="showModal">
                    {{ $t('global.action.edit') }}
                </a-button>
            </div>
            <div class="alarm-quota-subtitle">{{ $t('ProfilePage.set_notification_quota_insufficient_points') }}</div>
        </div>
        <div class="alarm-quota-content">
          <p>{{ $t('ProfilePage.alarm_quota') }}</p>
          <p class="alarm-quota-number">{{ data.prepaid_fund_info.baseline_amount | formatNumber }}</p>
        </div>
        <a-modal v-model="visible" :title="$t('ProfilePage.update_alarm_quota')" :footer="null"  class="alarm-quota-dialog" width='380px'>
            <a-form-model-item prop="content">
                <a-input-number v-model="newBaselineAmount" />
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary" @click="submitForm" block>
                {{ $t('global.action.submit') }}
                </a-button>
            </a-form-model-item>
        </a-modal>
    </div>
</template>
<script>

export default {
  props: {
    data: Object,
    updateAlarmQuota: Function,
    updatePermission: Boolean
  },
  data() {
    return {
      visible: false,
      newBaselineAmount: 0,
    };
  },
  watch: {
    data: {
      handler(newValue){
        if(newValue){
          this.newBaselineAmount = typeof newValue.baseline_amount === 'number' ? newValue.baseline_amount : 0;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal(){
      this.visible = false;
    },
    submitForm() {
      this.updateAlarmQuota({ baseline_amount: this.newBaselineAmount });
      this.closeModal();
    },
  },
};
</script>
