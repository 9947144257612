export default {
  lang: '한국어(韓文)',
  global: {
    game_type: {
      cockfight: '닭싸움',
      // spider: "",
      // color_game: "",
      // apple: "",
      // up_down: ""
    },
    fight_result: {
      meron: '메론',
      wala: '왈라',
      draw2: '드로우',
      draw: '드로우',
      cancel: '취소',
      // red: "",
      // blue: "",
      // gold: "",
      // white: "",
      // white3: "",
      // red3: "",
      // up: "",
      // down: ""
    },
    action: {
      yes: '예',
      no: '아니오',
      go: '진행',
      search: '검색',
      reset: '초기화',
      create: '생성',
      logout: '로그아웃',
      submit: '확인',
      edit: '수정',
      risk_edit: '위험 수정',
      lock_earning: '적립 잠금',
      decision_on_hold: '홀드 결정',
      cancel_on_hold: '홀드 취소',
      not_allow_bet: '베팅 불가',
      allow_bet: '베팅 허용',
      next: '다음',
      prev: '이전',
      step: '스텝',
      ok: '오케이',
      collapse: '줄이기',
      mask_video: '마스크 비디오',
      cancel_mask_video: '마스크 비디오 취소',
      records: '기록',
      cancel: '취소',
      export: '내보내기',
      select_file: '파일 선택',
      upload_image: '업로드',
      cancel_upload: '취소',
      update: '업데이트',
      update_all: '모두 업데이트',
      upload_all_images: '모두 업로드',
      cancel_update_all: '모두 취소',
      refresh_fights: '파이트 새로고침',
      open_bet: '열기',
      close_bet: '닫기',
      re_declare: '다시 선언',
      locking: '잠금',
      open_arena: '아레나 열기',
      close_arena: '아레나 닫기',
      refresh_cache: '캐시 새로고침',
      cancel_all: '모두 취소',
      delete: '삭제',
      monitor: '모니터링',
      monitor_video: '모니터링 비디오 열기',
      retry: '재시도',
    },
    common: {
      account: '계정',
      account_id: '넘버',
      active_status_list: {
        all: '모두',
        active: '활성',
        close: '닫기',
        enable: '사용',
        disable: '비활성',
      },
      action: '액션',
      add_settle_bet_info: '정산 API 베팅 정보 추가',
      agent: '에이전트',
      agent_account: '에이전트 계정',
      agent_count: '에이전트 개수',
      arena: '아레나',
      arena_suspend: '아레나 중단',
      all: '모두',
      amount: '금액',
      announcement: '공지사항',
      average_percentage: '파이트 당 평균',
      bet_amount: '베팅 금액',
      bet_count: '베팅 횟수',
      bet_type_list: {
        all: '모두',
        deposit: '입금',
        withdraw: '출금',
        OrderDeposit: '입금',
        OrderWithdraw: '출금',
        bet: '베팅',
        settle: '정산',
        cancel: '취소',
        resettle: '재정산',
        fail: '실패',
        init: '초기',
      },
      // business_status: '',
      // calculation_base: '',
      callback_url: '콜백 URL',
      change_password: '비밀번호 변경',
      confirm: '확인',
      confirm_password: '비밀번호 재확인',
      create_agent: '에이전트 생성',
      create_setting: '에이전트 설정',
      created_at: '생성일시',
      currency: '통화',
      // current_points: '',
      custom_logo: '커스텀 로고',
      custom_logo_list: {
        // odin: "",
        // gleague: "",
        // fiesta: "",
        // fiestaFruit: ""
      },
      date: '날짜',
      date_type: '날짜 타입',
      date_type_list: {
        BetAt: '베팅일시',
        GameDate: '게임날짜',
        SettleAt: '정산일시',
        OpenRound: '오픈 라운드',
      },
      declare_fight_winner: '파이트 승자 선언',
      defaultLanguage: '기본 언어',
      description: '설명',
      email: '이메일',
      fight_count: '파이트 횟수',
      fight_id: '파이트아이디',
      game_date: '게임날짜',
      game_type: '게임타입',
      // ggr: '',
      ip: '아이피',
      id: '넘버',
      kill_rate: '킬 레이트',
      language: '언어',
      last_login: '마지막 로그인',
      link_master: '링크 마스터',
      lock_earning: '적립 잠금',
      log: '로그',
      manager: '매니저',
      master: '마스터',
      max: '최대',
      merchant: '머천트',
      merchant_account: '머천트아이디',
      meron_wala: '메론 왈라',
      min: '최소',
      month: '월',
      multi_arena: '탭',
      multi_arena_status: {
        open: '열기',
        close: '닫기',
      },
      name: '이름',
      // none: '',
      odd: '배당',
      odds_type: '배당 타입',
      operator: '오퍼레이터',
      options: '옵션',
      order_num: '오더 넘버',
      order_status_list: {
        init: '초기화',
        process: '프로세스',
        success: '성공',
        failed: '실패',
        pending: '처리중',
        fail: '실패',
        error: '오류',
      },
      password: '비밀번호',
      payout: '페이아웃',
      platform_profit: 'GGR',
      player_account: '플레이어 계정',
      player_name: '플레이어 이름',
      player_bet_limit: '플레이어 싱글 베팅 한도',
      player_count: '플레이어 개수',
      player_round_limit: '플레이어 라운드 베팅 한도',
      pool_status: '풀 상황',
      profile: '프로파일',
      provider_name: '프로바이더 이름',
      rate: '레이트 (%)',
      rate_amount: '레이트 금액',
      risk_odd: '리스크 배당',
      role: '권한',
      risk_control: '리스크 제어',
      risk_type_list: {
        bet_limit: '베팅 한도 감소',
        no_login: '로그인 없음',
      },
      round_id: '파이트넘버',
      round_limit: '라운드 베팅 한도',
      round_status: '라운드 상황',
      round_status_list: {
        idle: '휴면',
        open: '열기',
        close: '닫기',
        locked: '잠김',
        last_call: '마지막 알림',
        arena_close: '아레나 종료',
        unsettled: '미정산',
        settled: '정산',
      },
      status: '상황',
      setting: '설정',
      settled_at: '정산일시',
      settle_zero: '금액이 0일때 정산 주문 생성',
      side: '베팅',
      skin: '테마 컬러',
      skin_list: {
        default: '기본',
        brown: '브라운',
        white: '화이트',
        black: '블랙',
        green: '그린',
        dark_and_light: '다크 & 라이트',
      },
      slug: '슬러그',
      theme: '테마 컬러',
      // top_agent: '',
      total: '총',
      total_bet_amount: '총 베팅 금액',
      total_bet_count: '총 베팅 횟수',
      total_platform_profit: '총 플랫폼 수익',
      total_rate_amount: '총 레이트 금액',
      total_user_bet: '총 사용자 베팅',
      total_valid_bet_amount: '총 유효 베팅 금액',
      type: '타입',
      updated_at: '업데이트일시',
      updated_setting: '업데이트 설정',
      usable_language: '사용 언어',
      user: '사용자',
      user_count: '사용자 수',
      video_url: '비디오 URL',
      // icp_video_url: "",
      valid_amount: '유효한 금액',
      winner: '승리자',
    },
    times: {
      unset: '언셋',
      today: '오늘',
      yesterday: '어제',
      this_week: '이번주',
      last_week: '지난주',
      this_month: '이번달',
      last_month: '지난달',
      custom: '사용자지정',
      tooltip: '내역 유지: 3개월(오늘 날짜 - 100일)',
    },
    menu: {
      group: {
        player: '플레이어',
        record: '기록',
        manager: '매니저',
        control: '제어판',
        Agent: '에이전트',
        MasterAgent: '마스터 에이전트',
        ArenaModerator: '아레나 모더레이터',
        Pool: '풀',
      },
      SingleModerator: '풀 매니지먼트',
      MultiModerator: '라운드 모더레이터',
      ControlPanel: '제어판',
      CurrentArena: '진행중 아레나',
      Players: '플레이어',
      PlayerCredits: '플레이어 크레딧',
      BetLog: '베팅 로그',
      GameResults: '게임 결과',
      Order: '주문',
      AgentList: '에이전트 목록',
      AgentSource: '마스터 에이전트 링크',
      AdminAgents: '에이전트',
      AgentShow: '에이전트 보기',
      AgentRoles: '에이전트 권한',
      AgentSubAccount: '매니저',
      MasterAgentList: '매니저 목록',
      MasterAgentSource: '매니저 링크',
      Merchants: '머천트',
      MerchantRoles: '머천트 권한',
      MerchantAdmin: '머천트 관리자',
      MerchantIpList: '아이피 제안 설정',
      ChangePassword: '비밀번호 변경',
      GGR: 'GGR',
      Profile: '수익',
      VideoSource: '비디오 소스',
      Transfer: '주문 문의',
      MonthlyProfit: '월 수익',
      DailyBettingStatistic: '일일 베팅 현황',
      ExchangeRate: '환전 레이트',
      CreateAgent: '에이전트 생성',
      PoolSetting: '풀 설정',
      MalayOddsSetting: '말레이시아 배당 설정',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: '{value}를 입력하세요/',
      select_value: '{value}를 선택하세요/',
      must_be_at_4_characters: '{value}는 반드시 4글자 이상이어야 합니다/',
      must_be_at_6_characters: '{value}는 반드시 6글자 이상이어야 합니다/',
      must_be_between_characters: '{value}는 반드시 최소 {value} 이상, 최대 {value} 이하 글자여야 합니다/',
      must_be_between_number: '{value}는 반드시 최소 {value} 이상, 최대 {value} 이하여야 합니다/',
      must_be_alphanumeric: '{value}는 반드시 영문자 숫자 조합이어야 합니다/',
      must_be_number: '반드시 숫자여야 합니다/',
      must_be_number_value: '{value}는 반드시 숫자여야 합니다/',
      two_no_match: '두 입력값이 일치하지 않습니다!',
      failed_to_create: '{value} 생성에 실패하였습니다/',
      failed_to_edit: '{value} 수정에 실패하였습니다/',
      success_to_create: '{value} 생성에 성공하였습니다/',
      success_to_edit: '{value} 수정에 성공하였습니다/',
      success_to_change: '{value}를 성공적으로 수정하였습니다/',
      failed_to_update: '{value} 업데이트에 실패하였습니다/',
      decision_on_hold: '클릭시 아레나가 중단됩니다/',
      cancel_on_hold: '클릭시 아레나가 시작됩니다/',
      close_bet: '클릭시 아레나의 베팅이 종료됩니다/',
      open_bet: '클릭시 아레나의 베팅이 오픈됩니다/',
      mask_video: '클릭시 GC 비디오가 마스킹됩니다/',
      cancel_mask_video: '클릭시 GC 비디오가 시작됩니다/',
      delete: '{value}를 삭제할까요?',
      choose_arena: '아레나를 선택하세요/',
      enter_fight_number: '파이트넘버 시작을 입력하세요/',
      select_date: '날자를 선택하세요/',
      select_one: '최소 하나의 {value}를 선택하세요/',
      browser_not_supported:
        '사용하시는 브라우저를 지원하지 않습니다/ OK 버튼을 클릭하여 구글 크롭 또는 사파리 브라우저를 실행 하세요/',
      network_error_title: '네트워크 오류',
      network_error_info: '인터넷 연결을 확인하시고 페이지를 새로고침하세요/',
      must_be_greater_than_zero: '금액은 반드시 0과 같거나 높아야 합니다/',
      must_be_greater_than_min: '최대 금액은 반드시 최소 금액과 같거나 높아야 합니다/',
      incorrect_ip: '아이피 형식이 잘못되었습니다/',
      criteria_be_selected: '검색 유형이 반드시 선택되어야 합니다/',
      success_to_delete: '{value}가 성공적으로 삭제되었습니다/',
      failed_to_delete: '{value} 삭제에 실패하였습니다/',
      failed_to_delete_with_reason: '{value} 업데이트가 다음과 같은 이유로 실패하였습니다: {reason}',
      account_placeholder: '영문자와 숫자 조합의 4 ~ 16 글자',
      password_placeholder: '6 ~ 20 글자',
      confirm_password_placeholder: '동일한 비밀번호를 다시한번 입력하세요/',
      date_type_hint:
        '보고서는 「{dateType}」을 기준으로 합니다/ 어제 데이터는 매일 10:00(GMT+8)에 생성되며, 오늘 데이터는 제외됩니다/',
      please_create_role: '매니저를 생성하기 전 권한을 먼저 생성하세요/',
      max_length: "{value}'의 최대 길이는 {length}",
      whole_url: '전체 URL을 입력하세요/',
      A_greater_tham_B: '{value_a}는 반드시 {value_b}보다 커야 합니다/',
      setting_player_round_max: '최소 금액 및 최대 금액 설정',
      setting_player_bet_max: '최대 금액 설정',
      reset_data: '데이터를 초기화 할까요?',
      monitor: '클릭시 모니터링 페이지가 열립니다/',
      failed_to_monitor: '모니터링 페이지를 여는데 실패하였습니다/',
      // failed_to_fetch: '',
      // not_found: '',
    },
  },
  AgentsPage: {
    master_account: '마스터 에이전트 아이디',
    languageList: '언어 목록',
    create_master_agent: '마스터 에이전트 생성',
    rate: '커미션 레이트 (%)',
    rate_note:
      '하위 에이전트의 커미션 레이트가 상위 에이전트의 커미션 레이트보다 높은 경우, 시스템은 자동으로 새로운 커미션 레이트로 감소 조정합니다/',
    max: '최대: {value}',
    abbr: '줄임말',
    abbr_placeholder: '1 ~ 6 글자',
    edit_agent: '에이전트 수정',
    // agent_credit_points_options: '',
  },
  AgentSubAccountPage: {
    authority: '권한',
    authority_list: {
      read: '읽기',
      editor: '편집자',
    },
    create_sub_account: '매니저 생성',
  },
  BetLogPage: {
    win_lose: '승리 패배',
    bet_return: '베팅 반환',
    is_settled: '정산 완료',
    device: '접속기기',
    amount_type: '금액 타입',
    amount_range: '금액 범위',
  },
  ChangePasswordPage: {
    current_password: '현재 비밀번호',
    current_password_placeholder: '현재 비밀번호를 입력하세요',
    new_password: '새로운 비밀번호',
  },
  ArenaModeratorPage: {
    video_rate: '비디오 레이트',
    online_users: '온라인 사용자',
  },
  ControlPanelPage: {
    arena_on: '온',
    arena_off: '오프',
    no_announce: '공지사항이 없습니다/ "편집"을 클릭하여 업데이트하세요/',
    edit_announce: '공지사항 편집',
    open_arena_title: '아레나를 오픈할까요?',
    close_arena_title: '아레나를 정말 종료할까요?',
    start_fight: '시작 파이트 아이디',
    start_time: '시작 시간',
    open_betting: '베팅을 오픈할까요?',
    close_betting: '베팅을 종료할까요?',
    re_declare_title: '다시 선언할까요?',
    re_declare_content: '파이트 아이디 {id} 승자 다시 선언',
    lock_earing_title: '적립을 잠금할까요?',
    lock_earing_content: '적립 잠금 파이트 아이디 {id}',
    click_again_to: '다시 클릭하기',
    file_upload: '파일 업로드',
    arena_cover: '아레나 커버',
    mask_file: '마스크 파일',
    schedule: '일정',
    edit_provider_url: '제공사 URL',
    img_path: '이미지 경로',
    below_px_value: '{value} px 이하',
    img_format: '파일 형식',
    img_recommended_size: '사이즈',
    file_size: '파일 사이즈',
    image: '이미지',
    file_no_selected: '파일이 선택되지 않았습니다/',
    no_image: '이미지가 없습니다/',
    edit_video_url: '비디오 URL 수정',
    pool_fight_number: '풀/파이트넘버',
    round_master: '라운드 마스터',
    message: {
      input_fight: '파이트 넘버를 입력하세요/',
      max_image_size: '이미지의 최대 사이즈는 {value}kb 입니다/',
      error_image_type: '이미지 타입의 오류가 있습니다/ Jpg, jpeg, png만 사용 가능합니다/',
      delete_image_placeholder: '{value}를 정말로 삭제하시겠습니까?',
      success_to_earn_locked: '파이트 아이디 {value}에 대한 적립이 잠금으로 설정되었습니다/',
      fail_to_earn_locked: '파이트 아이디 {value}에 대한 적립 잠금이 다음과 같은 이유로 실패하였습니다: {reason}/',
      success_to_cancel_bet: '파이트 아이디 {value}에 대한 모든 베팅이 취소처리 되었습니다/',
      fail_to_cancel_bet: '파이트 아이디 {value}에 대한 취소 처리가 다음과 같은 이유로 실패하였습니다: {reason}/',
      affect_all_round: '{{value}} 아래 모든 라운드에 영향이 발생합니다/',
      // operator_repeat_and_retry: ""
    },
  },
  CurrentArenaPage: {
    count: '횟수',
    unsettlement: '미정산',
    fight_action: '파이트 액션',
  },
  GameResultsPage: {
    open_bet_at: '오픈 베팅',
    close_bet_at: '완료 베팅',
    declare_at: '선언',
    reload_at: '리로드',
    cancel_at: '취소',
    total_rate: '총 레이트',
    total_meron: '총 메론',
    total_wala: '총 왈라',
    total_draw: '총 드로우',
    status_list: {
      initial: '초기',
      betting: '베팅',
      fighting: '파이팅',
      finished: '종료',
      reloaded: '리로드',
      earn_locked: '정산',
      last_call: '마지막 알림',
      cancel: '취소',
    },
    time: '시간',
    reason: '이유',
    cancel_reasons: {
      3000: '원-사이트 베팅',
      3001: '배당이 유효하지 않습니다/',
      canceled_manual: '파이트 취소 - 수동',
      canceled_ws: '파이트 취소 - 웹훅',
    },
    open_round: '오픈 라운드',
  },
  LoginPage: {
    log_in: '로그인 없음',
    otp: '인증번호',
    message: {
      success: '로그인에 성공하였습니다!',
      failed: '계정 또는 비밀번호가 잘못 입력되었습니다/',
    },
  },
  MerchantsPage: {
    admin: '관리자',
    role: '권한',
    create_merchant: '머천트 생성',
    name: '머천트 이름',
    message: {
      master_agent_only_select_one: '마스터 / 에이전트에서 1개의 옵션만 선택하실 수 있습니다/',
    },
  },
  MerchantRolesPage: {
    current_arena: '진행중 아레나',
    can_permission_pages: '페이지',
    use_account: '사용 계정',
    create_role: '권한 생성',
    edit_role: '권한 수정',
    role_name: '권한 이름',
    color: '컬러',
    can_use_pages: '접근 가능한 페이지',
    arena_on_off: '아레나 온/오프',
    fight_control: '파이트 제어',
    lock_earning: '적립 잠금',
    announcement: '공지사항',
    decision_on_hold: '홀드 결정',
    monitor: '비디오 모니터',
    no_bets_allowed: '베팅이 허용되지 않습니다/',
    mask_video: '마스크 비디오',
    read: '읽기',
    update: '업데이트',
    create: '생성',
    delete: '삭제',
    edit: '수정',
    dashboard_card: '총 표시',
    confirm: '확인',
    approve: '승인',
    arena_cover: '아레나 커버',
    mask_file: '마스크 파일',
    schedule: '일정',
    export: '내보내기',
    edit_provider_url: '제공사 URL',
    display_rate: '레이트 보기',
    account_to_use: '해당 권한을 사용하는 계정이 아직 존재합니다/',
    view_schedule: '일정 보기',
    view_log: '로그 보기',
    risk_edit: '위험 수정',
  },
  MerchantAdminPage: {
    edit_manager: '매니저 수정',
    create_manager: '매니저 생성',
  },
  MerchantIpPage: {
    master_agent: '마스터 또는 에이전트',
    create_ip: '아이피 생성',
  },
  DailyBettingStatisticPage: {
    yesterday_percentage: 'TVA 어제 %',
    last_week_percentage: 'TVA 지난주 %',
    user_count: '사용자 수',
    ave_per_fight_percentage: 'APF 어제 %',
    last_week_ave_per_fight_percentage: 'APF 지난주 %',
  },
  OrderPage: {
    after_balance: '이후 금액',
  },
  PlayersPage: {
    balance: '금액',
    fee: '수수료',
    current_sign_in_ip: '현재 로그인 아이피',
    total_deposit: '총 입금',
    total_withdraw: '총 출금',
    total_win_lose: '총 승리 패배',
  },
  PlayerCreditsPage: {
    id: '아이디',
    type: {
      deposit: '입금',
      withdraw: '출금',
      settle: '주문 정산',
      cancel: '주문 취소',
      bet: '베팅',
    },
    before_amount: '이전 금액',
    after_amount: '이후 금액',
  },
  ProfilePage: {
    setting_round_draw_max: '1개 라운드의 최대 금액 설정',
    setting_round_draw_max_part1: '최대 금액 설정',
    setting_round_draw_max_part2: '1개 라운드',
    player_limit: '플레이어 베팅 한도',
    setting_player_max: '최소 금액 및 최대 금액 설정',
    basic_info_title: '기본 정보',
    basic_info_text: '에이전트 기본 정보',
    integration_info: '연동 정보',
    auth: '인증',
    secret: '시크릿 키',
    api_doc_url: 'API 문서 URL',
    api_url: 'API URL',
    video_traffic: '비디오 트래픽',
    remain_video_data: '남은 비디오 데이터',
    update_video_data: '업데이트 비디오 데이터',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
    // warning_no_enough_point: '',
    // please_decrease_own_points_to_zero: '',
    // please_remove_all_designated_agents: '',
    // please_confirm_all_agents_settled: '',
  },
  VideoSourcePage: {
    source: '비디오 소스',
    agent_source: '마스터 에이전트 비디오 소스',
    accessable_merchants: '접근 가능한 머천트',
  },
  TransferPage: {
    slug: '슬러그',
    data: '데이터',
    res: '응답',
    round_id: '라운드 아이디',
    setting_master_id: '설정 마스터 아이디',
    num: '넘버',
    resend: '재전송',
    bet_num: '베팅 넘버',
    resend_note: '노트',
    resend_mark: '마크',
    message: {
      success_to_resend: '재전송 성공, 아니오: {value}',
      fail_to_resend: '재전송 실패, 아니오: {value}',
      success_to_remark: '리마크 성공, 아니오: {value}',
      fail_to_remark: '리마크 실패, 아니오: {value}',
    },
  },
  ExchangeRatePage: {
    currency: '통화',
    currency_name: '통화명',
    last_approved_rate: '마지막 레이트',
    today_rate: '오늘의 레이트',
    ref_rate: '리퍼런스 레이트',
    ref_rate_time: '리퍼런스 시간',
    tbd_confirm_rate: '확인 레이트',
    tbd_modify_rate: '수정 레이트',
    record: '기록',
    current_time: '현재 시간',
    last_approved_time: '마지막 승인 시간',
    base_currency: '기반 통화',
    fetch_exchange_rate: '환율 레이트 가져오기',
    approve_rate: '승인 레이트',
    rate_warning: '*중요: 환율 레이트는 승인된 이후 바로 적용 됩니다!',
    log: '환율 레이트 로그',
    confirmed_by: '확인 담당자',
    confirmed_time: '확인 시간',
    approved_by: '승인 담당자',
    approved_time: '승인 시간',
    exchange_rate: '환율 레이트',
  },
  CreateAgentPage: {
    parent_agent: '상위 에이전트',
    environment: '개발환경',
    staging: '개발서버',
    production: '실서버',
    brand: '브랜드',
    trade_mode: '트레이드 모드',
    transfer_wallet: '트랜스퍼 월렛',
    single_wallet: '심리스 월렛',
    level_sub_agent_with_value: '레벨 {value} 서브-에이전트',
    delete_agent_with_value: '레벨 {value} 서브-에이전트 삭제',
    add_agent_with_value: '레벨 {value} 서브-에이전트 추가',
    agent_info: '에이전트 정보',
    // designated_agent: '',
    message: {
      select_master_first: '마스터 에이전트를 먼저 선택하세요/',
      agent_same_master: '상위 에이전트가 마스터랑 동일합니다/',
      please_refresh_cache: '캐시를 새로고침하여 새로운 에이전트 목록을 확인하세요/',
      log_saved_time: '모든 로그 기록은 {value} 시간까지 저장 됩니다/',
      success_refresh_cache: '캐시 새로고침 성공',
      fail_refresh_cache: '캐시 새로고침 실패',
    },
  },
  PoolSetting: {
    pool_name: '풀 이름',
    odds_refresh_frequency: '새로고침 주기',
    last_call_percentage: '마지막 알림 (%)',
    close_bet_percentage: '종료 베팅 (%)',
    per_bet: '베팅 당',
    per_round: '라운드 당',
    risk_threshold: '위험 쓰레스홀드',
    current_schedule: '현재 일정',
    new_schedule: '새로운 일정',
    update_schedule: '업데이트 일정',
    schedule: '일정',
    view: '뷰',
    start_time: '시작 시작',
    end_time: '종료 시간',
    pool_configuration_options: '풀 설정 옵션',
    expect_date: '설정 적용 날짜',
    expect_time: '설정 적용 시간',
    add_new_pool_configuration: '새로운 풀 설정 추가',
    exceed_time: '현재 시간이 설정 적용 시간을 초과하였습니다/',
    effective_time: '적용 시간',
    import_current_schedule: '현재 일정 가져오기',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: '',
    // change_agent: '',
    // view_complete_record: '',
    // point_usage: '',
    // points_deduction: '',
    // positive_numbers: '',
    // negative_numbers: '',
    // points_addition: '',
  },
}
