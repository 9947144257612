<template>
  <div class="frame agents-page">
    <a-page-header
      :title="$t(`global.menu.AgentCreditPoints`)"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <Currency
              :inputCurrency.sync="form.currency"
              :inputMaster.sync="form.master"
              :showCurrency="true"
              :showMaster="true"
              :showAgent="false"
              :multipleMaster="true"
              :multipleAgent="true"
              :currencyRequired="true"
            />
            <a-form-model-item ref="agent" :label="$t('global.common.agent')" prop="agent">
              <a-select
                show-search
                :showArrow="true"
                v-model="form.agent_ids"
                :mode="'multiple'"
                @dropdownVisibleChange="getAgentList"
                :filter-option="filterOption"
              >
                <template #notFoundContent v-if="agentLoading">
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="(agent_ids) in agentList" :value="agent_ids.id" :key="agent_ids.id">
                    {{ agent_ids.account }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="status" :label="$t('global.common.business_status')" prop="status">
              <a-select v-model="form.status">
                <a-select-option v-for="status in statusList" :value="status.value" :key="status.value">
                  {{ $t(`global.common.active_status_list.${status.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="points" :label="'≦'+$t('global.common.current_points')" prop="points">
              <a-input
                v-model.trim="form.points"
                type="number"
              />  
            </a-form-model-item>
          </div>
          <div class="search-group">
            <div class="search-btns">
              <a-button :disabled="decreaseDisabled" v-if="decreaseShow" @click="groupDecrease" class="add-btn">{{ $t('AgentCreditPointsPage.group_decrease') }}</a-button>
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <!-- 大 Table -->
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :data-source="agents"
            :pagination="pagination"
            :loading="loading"
            rowKey="account"
            @change="handleTableSequence"
            :row-selection="rowSelection"
          >
            <template v-for="column in columns">
              <!-- 更好的寫法 -->
              <template :slot="column.slots.title">
                {{ column.singleI18n ? $t(`AgentCreditPointsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}
                {{ column.slots.title === 'agent_account' && checkCounts ? `(${$t('AgentCreditPointsPage.has_selected_value', {value: checkCounts})})` : ''}}
              </template>
            </template>
            <template slot="account" slot-scope="account">
              {{ account }} 
            </template>
            <template slot="active" slot-scope="active">
              <div class="table-status-item">
                <span class="status-icon" :data-status="active ? 'active' : 'close'"></span>
                <span class="status-text">{{ $t(`global.common.active_status_list.${active ? 'active' : 'close'}`) }}</span>
              </div>
            </template>
            <template slot="ancestry" slot-scope="ancestry">
              <a-tooltip placement="bottom" :title="joinSuperior(ancestry).title">
                <span>{{ joinSuperior(ancestry).text }}</span>
              </a-tooltip>
            </template>
            <template slot="base_mode" slot-scope="base_mode">
              {{ $t(`global.common.${base_mode}`)}}
            </template>
            <template slot="action" slot-scope="action ,row">
              <a-button type="primary" :disabled="row.active" @click="handleChangePoints(row)">{{ $t(`AgentCreditPointsPage.edit_points`) }}</a-button>
            </template>
            <template slot="history" slot-scope="history ,row">
              <a-button type="primary" @click="openHistoryTable(row.id)">{{ $t(`AgentCreditPointsPage.edited_record`) }}</a-button>
            </template>
            <template slot="balance" slot-scope="balance">
              {{ (balance) | formatNumberWithPoint }}
            </template>
            <template slot="updated_at" slot-scope="updated_at">
              <span>
                {{ (updated_at) | formatTimesToUTC8 }}
              </span>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <!-- 修改分數 Components -->
    <AgentModifyScoreDialog 
      :inputHistoryPointsCheckDialog="historyPointsCheckDialog"
      :inputChangePointsData="changePointsData"
      :inputVisiblePoints="visiblePoints"
      :inputForm="form"
      :inputOperatorSwitch="operatorSwitch"
      :inputHandleCancel="handleCancel"
      :pointsCheckOk="historyPointsCheckOk"
      :pointsCheckCancel="historyPointsCheckCancel"
      :inputHandleOk="handleOk"
      :inputAmountLoading="amountLoading"
      :inputAmountLoadingFalse="amountLoadingFalse"
    /> 
    <!-- 修改紀錄 Components -->
    <AgentPointsModifyHistory
      :inputVisibleHistory="visibleHistory"
      :historyId="historyTableId"
      :inputHistoryClose="historyClose"
    />
    <!-- 批量下分 Components -->
    <AgentGroupDecrease 
      :inputVisibleDecrease="visibleDecrease"
      :inputDecreaseAgents="decreaseAgents"
      :inputDecreaseCancel="decreaseCancel"
      :inputOnSearch="onSearch"
    />
  </div>
</template>

<script>
import { 
  getPrepaidFundsAgent,
  getPrepaidFunds,
  putPrepaidFundsPointsModify,
} from '@/../api';
import Currency from '@/../src/components/currency_selection';
import AgentModifyScoreDialog from '@/../src/components/agents/AgentModifyScoreDialog';
import AgentPointsModifyHistory from '@/../src/components/agents/AgentPointsModifyHistory';
import AgentGroupDecrease from '@/../src/components/agents/AgentGroupDecrease';
import queryString from 'query-string';

export default {
  components: {
    Currency,
    AgentModifyScoreDialog,
    AgentPointsModifyHistory,
    AgentGroupDecrease
  },
  data() {
    return {
      data: [],
      agents: [],
      decreaseAgents:[],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      loading: false,
      amountLoading: false,
      visiblePoints: false,
      visibleHistory: false,
      visibleDecrease: false,
      operatorSwitch: true,
      checkCounts: 0,
      form: {
        master: [],
        agent_ids: [],
        status: 'all',
        points: '',
        currency: "",
      },
      statusList: [
        {
          label: 'all',
          value: 'all'
        },
        {
          label: 'active',
          value: 1
        },
        {
          label: 'close',
          value: 0
        }
      ],
      agentList:[],
      changePointsData: { 
        account: "", 
        balance: 0, 
        after_points: 0, 
        amount: 0, 
        type: "", 
        minus_disabled: true,
        agent_ids: null,
      },
      selectedRowKeys: [],
      // 排序
      sequence: '',
      sortedInfo: {
        order: undefined
      },
      historyTableId: 0,
      agentLoading: false,
      historyPointsCheckDialog: false,
    };
  },
  computed: {
    columns(){
      return  [
        {
          slots: { title: 'agent_account' },
          dataIndex: 'account',
          key: 'account',
          scopedSlots: { customRender: 'account' },
          align: 'center',
          singleI18n: false,
        },
        {
          slots: { title: 'parent_agent' },
          dataIndex: 'ancestry',
          key: 'ancestry',
          align: 'center',
          scopedSlots: { customRender: 'ancestry' },
          singleI18n: true,
        },
        {
          slots: { title: 'business_status' },
          dataIndex: 'active',
          key: 'active',
          scopedSlots: { customRender: 'active' },
          align: 'center',
          singleI18n: false,
        },
        {
          slots: { title: 'calculation_base' },
          scopedSlots: { customRender: 'base_mode' },
          dataIndex: 'base_mode',
          key: 'base_mode',
          align: 'center',
          singleI18n: false,
        },
        {
          slots: { title: 'current_points' },
          dataIndex: 'balance',
          key: 'balance',
          scopedSlots: { customRender: 'balance' },
          align: 'center',
          sorter: (a, b) => a.balance - b.balance,
          singleI18n: false,
          sortOrder: this.sortedInfo.columnKey === 'balance' && this.sortedInfo.order,
        },
        {
          slots: { title: 'action' },
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
          singleI18n: false,
        },
        {
          slots: { title: 'edited_record' },
          scopedSlots: { customRender: 'history' },
          dataIndex: 'history',
          key: 'history',
          align: 'center',
          singleI18n: true,
        },
        {
          slots: { title: 'last_edited_time' },
          scopedSlots: { customRender: 'updated_at' },
          dataIndex: 'updated_at',
          key: 'updated_at',
          align: 'center',
          singleI18n: true,
        },
      ];
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log('selectedRowKeys selectedRows: ', selectedRowKeys, selectedRows);
          this.selectedRowKeys = selectedRowKeys;
          this.checkCounts = selectedRowKeys.length;
          this.decreaseAgents = selectedRows;
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.active === true, // Column configuration not to be checked
          },
        }),
      };
    },
    decreaseDisabled(){
      return this.checkCounts < 1;
    },
    decreaseShow(){
      return this.agents.length !== 0;
    },
    searchbarAgentsClear(){
      // 需要綁定的資料
      return {
        currency:this.form.currency, 
        master:this.form.master
      };
    }
  },
  methods: {
    // 搜尋
    onSearch() {
      // 按下搜尋後回到第一頁 
      this.pagination.current = 1;
      // 排序狀態清空
      this.resetBalanceSort();
      // 清空勾選狀態
      this.selectedRowKeys = [];
      this.fetch();
    },
    // 大 Table 排序&更改頁數時
    handleTableSequence(pagination, filters, sorter) {
      // 判斷排序&更改頁數後 不打API區塊
      // 單頁pageSize大於Data.total總數量 且不是按下改變頁數時 只改變排序不打API
      if((this.pagination.pageSize >= this.pagination.total) && (this.pagination.pageSize === pagination.pageSize)){
        this.sortedInfo = sorter;
        return;
      }
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      // 按下排序參數 回到第一頁
      if((this.sortedInfo.order != sorter.order) && (this.sortedInfo.order != '')){
        this.pagination.current = 1;
      }
      this.sortedInfo = sorter;
      this.fetch();
    },
    resetBalanceSort() {
      this.sortedInfo = {
        order: '',
        columnKey: 'balance',
      };
    },
    // 大 Table Fetch
    async fetch() {
      // console.log(params);
      if(this.loading) return;
      this.loading = true;
      this.selectedRowKeys = [];
      this.checkCounts = 0;
      const { pageSize, current } = this.pagination;      
      const { currency, master:master_ids, agent_ids, status:active, points:balance } = this.form;
      const params = ({ 
        currency, 
        master_ids: master_ids === null ? undefined : master_ids, 
        agent_ids, 
        active, 
        balance, 
        results: pageSize, 
        page: current, 
        sequence:this.sortedInfo.order === undefined ? '' : this.sortedInfo.order 
      });
      const getString = queryString.stringify(params,{arrayFormat: 'bracket'});

      await getPrepaidFunds(getString)
        .then(({ data }) => {
          // console.log(data);
          this.pagination.total = data.total;
          this.agents = data.agents;
        })
        .catch((err) => {
          this.$message.error(this.$t(`global.message.failed_to_fetch`, { value: this.$t(`global.menu.AgentCreditPoints`) }));
        }).finally(()=>{
          this.loading = false;
        });
    },
    // 搜尋 Bar Reset
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.master = [];
      this.form.agent_ids = [];
    },
    historyClose(){
      this.visibleHistory = false;
    },
    // 超過三個層級 僅顯示第一層和最高層
    joinSuperior(items) {
      if (items.length > 3) {
      const hoverText = items.slice(1, -1).join(' / ');
      return {
        text: `${items[0]} / ... / ${items[items.length - 1]}`,
        title: hoverText,
      };
      }
      return {
        text: items.join(' / '),
        title: '',
      };
    },
    handleChangePoints(data){
      // console.log("handleChangePoints");
      // console.log(data);
      // data.active == true時 不給下分
      this.visiblePoints = true;
      this.changePointsData.balance = data.balance;
      this.changePointsData.account = data.account;
      this.operatorSwitch = true;
      this.changePointsData.amount = 0;
      this.changePointsData.agent_ids = data.id;
      this.changePointsData.type = '';
    },
    async openHistoryTable(id){
      // console.log("openHistoryTable");
      this.visibleHistory = true;
      this.historyTableId = id;
    },
    handleOk(){    
      // console.log("handleOk");
      this.changePointsData.after_points = Number(this.changePointsData.balance) + (this.changePointsData.type === 'increase' ? 1 : -1) * Number(this.changePointsData.amount);
      this.historyPointsCheckDialog = true;
    },
    // 修改分數api
    async putAmountFetch(id, form){
      // console.log("putAmountFetch",this.amountLoading);
      if(this.amountLoading) return;
      this.amountLoading = true;
      await putPrepaidFundsPointsModify({
        id: id,
        form:{
          ...form
        }
      }).then(({ data }) => {
        this.fetch();
        this.$message.success(this.$t(`global.message.success_to_change`, { value: this.$t(`AgentCreditPointsPage.points`) }));
        this.visiblePoints = false;
        this.visibleDecrease = false;
        this.operatorSwitch = false;
        this.historyPointsCheckCancel();
      }).catch((error)=>{
        this.amountLoading = false;
        this.$message.error(this.$t(`global.message.failed_to_update`, { value: this.$t(`AgentCreditPointsPage.points`) }));
      });
    },
    amountLoadingFalse(){
      this.amountLoading = false;
    },
    // 修改分數確認視窗確認
    historyPointsCheckOk(){
      // console.log('historyPointsCheckOk',this.amountLoading);
      // console.log(this.changePointsData);
      const { type, amount } = this.changePointsData;
      this.putAmountFetch(this.changePointsData.agent_ids, { agent_ids:this.changePointsData.agent_ids, type:type, amount:amount, is_batch: false });
    },
    historyPointsCheckCancel(){
      // console.log('historyPointsCheckCancel');
      this.historyPointsCheckDialog = false;
    },
    handleCancel(){
      // console.log("handleCancel");
      this.visiblePoints = false;
      this.operatorSwitch = false;
    },
    groupDecrease(){
      this.visibleDecrease = true;
    },
    decreaseCancel(){
      this.visibleDecrease = false;
    },
    async getAgentList(open){
      if(!open) return;
      const thisMasterGetData = this.form.master && this.form.master.map(id => `master_ids[]=${id}`).join('&');
      this.agentLoading = true;
      await getPrepaidFundsAgent(!thisMasterGetData ? 'currency=' + this.form.currency : thisMasterGetData)
        .then(({ data }) => {
          this.agentList = data.agents;
        })
        .catch((err) => {
          this.$message.error(this.$t(`global.message.failed_to_fetch`, { value: this.$t(`global.common.agent`) }));
        }).finally(()=>{
          this.agentLoading = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  watch: {
    searchbarAgentsClear:{
      async handler(newValue){
        this.form.agent_ids = [];
      },
    }
  },
};
</script>
