export default {
  lang: 'Bahasa Indonesia(印尼文)',
  global: {
    game_type: {
      // cockfight: '',
      // spider: '',
      // color_game: '',
      // apple: '',
      // up_down: '',
    },
    fight_result: {
      meron: 'Naga',
      wala: 'Feniks',
      draw: 'seri',
      draw2: 'Seri',
      cancel: 'Batal',
      red: 'Merah',
      blue: 'Biru',
      gold: 'Emas',
      // white: '',
      // red3: '',
      // white3: '',
      // down: '',
      // up: '',
    },
    action: {
      yes: 'Konfirmasi eksekusi',
      no: 'Tidak eksekusi',
      go: 'Maju',
      search: 'Mencari',
      reset: 'Atur ulang',
      create: 'Bangun',
      logout: 'Keluar',
      submit: 'Konfirmasi kirim',
      edit: 'Edit',
      // risk_edit: '',
      lock_earning: 'Pembayaran',
      decision_on_hold: 'Berhenti sementara pertandingan',
      cancel_on_hold: 'Mulai pertandingan',
      not_allow_bet: 'Tidak boleh taruhan',
      allow_bet: 'Boleh taruhan',
      next: 'Langkah berikutnya',
      prev: 'Langkah sebelumnya',
      step: 'Langkah',
      ok: 'Yakin',
      collapse: 'Tutup',
      mask_video: 'Video samar',
      cancel_mask_video: 'Tutup video samar',
      records: 'Catatan',
      cancel: 'Batal',
      export: 'Ekspor',
      select_file: 'Pilih file',
      upload_image: 'Unggah gambar',
      cancel_upload: 'Batalkan pengunggahan',
      // update: '',
      // update_all: "",
      // upload_all_images: "",
      // cancel_update_all: "",
      refresh_fights: 'Refresh pertandingan',
      open_bet: 'Menerima taruhan',
      close_bet: 'Tutup taruhan',
      re_declare: 'Ulangi pengumuman pemenang',
      locking: 'Dalam pembayaran',
      open_arena: 'Memulai pertarungan #',
      close_arena: 'Menutup semua putaran di bawah Pool',
      // refresh_cache: '',
      // cancel_all: '',
      delete: 'Hapus',
      monitor: 'Pantau',
      // monitor_video: '',
      // retry: '',
      // refresh: '',
    },
    common: {
      account: 'akun',
      account_id: 'nomer',
      active_status_list: {
        all: 'Semua',
        active: 'Buka',
        close: 'tutup',
        // enable: '',
        // disable: ''
      },
      action: 'Operasi',
      // add_settle_bet_info: '',
      agent: 'agen',
      agent_account: 'Akun agen',
      agent_count: 'Jumlah agen',
      arena: 'Arena',
      arena_suspend: 'Berhenti sementara pertandingan ayam',
      all: 'Semua',
      amount: 'Nominal',
      announcement: 'Umumkan',
      average_percentage: 'Rata-rata per pertandingan',
      bet_amount: 'Nilai taruhan',
      bet_count: 'Jumlah taruhan',
      bet_type_list: {
        all: 'Semua',
        deposit: 'Uang masuk',
        withdraw: 'Uang keluar',
        OrderDeposit: 'Uang masuk',
        OrderWithdraw: 'Uang keluar',
        bet: 'Bertaruh',
        settle: 'Penghitungan',
        cancel: 'Batal',
        resettle: 'Penghitungan ulang',
        fail: 'Gagal',
        init: 'Awal',
      },
      // business_status: '',
      // calculation_base: '',
      // callback_url: '',
      change_password: 'Ubah kata sandi',
      confirm: 'Konfirmasi',
      confirm_password: 'Konfirmasi kata sandi',
      create_agent: 'Tambah agen',
      // create_setting: '',
      created_at: 'Waktu membuat',
      currency: 'Mata uang',
      // current_points: '',
      // custom_logo: '',
      // custom_logo_list: {
      //   odin: '',
      //   gleague: '',
      //   fiesta: '',
      //   fiestafruit: '',
      // },
      date: 'Tanggal',
      date_type: 'Opsi waktu',
      date_type_list: {
        BetAt: 'Waktu taruhan',
        GameDate: 'Tanggal pertandingan',
        SettleAt: 'Waktu penghitungan',
        OpenRound: 'Waktu mulai',
      },
      declare_fight_winner: 'Pemenagnya adalah',
      defaultLanguage: 'Bahasa default',
      description: 'Penjelasan',
      email: 'Surel',
      fight_count: 'Jumlah pertandingan',
      fight_id: 'Fight ID',
      game_date: 'Tanggal pertandingan',
      // game_type: '',
      // ggr: '',
      ip: 'IP',
      id: 'nomer',
      kill_rate: 'Persentase pembunuhan',
      language: 'Bahasa',
      last_login: 'Penggabungan akhir',
      // link_master: "",
      lock_earning: 'Pembayaran',
      log: 'Log',
      // main_arena: '',
      // main_pool: '',
      manager: 'Mengelola',
      master: 'Pusat',
      max: 'nilai tertingging',
      merchant: 'bisnis',
      merchant_account: 'Akun dagang',
      meron_wala: 'Meron Wala',
      min: 'nilai terendah',
      month: 'Bulan',
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: 'Nama',
      // none: '',
      odd: 'Persentasi pembayaran',
      odds_type: 'Jenis rate pembayaran',
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: 'Operator',
      // options: '',
      // order_num: "",
      order_status_list: {
        init: 'Sedang diproses',
        process: 'Sedang diproses',
        success: 'Berhasil',
        failed: 'Gagal',
        pending: 'Sedang ditunda',
        fail: 'Gagal',
        error: 'Kesalahan',
      },
      password: 'Kata sandi',
      payout: 'Persentasi pembayaran',
      platform_profit: 'Laba platform',
      player_account: 'Akun pemain',
      // player_name: '',
      player_bet_limit: 'Taruhan maksimal pemain',
      player_count: 'Jumlah pemain',
      player_round_limit: 'Taruhan maksimal pemain per putaran',
      // pool_status: '',
      profile: 'Informasi agen',
      provider_name: 'Nama pemberi',
      rate: 'Komisi (%)',
      rate_amount: 'Jumlah komisi',
      risk_odd: 'Rate pembayaran kemenangan',
      role: 'Peran',
      // risk_control: '',
      risk_type_list: {
        // bet_limit: '',
        // no_login: '',
      },
      round_id: 'jumlah petarungan',
      round_limit: 'Taruhan maksimal per putaran',
      // round_status: '',
      round_status_list: {
        idle: 'kosong',
        open: 'Dapat menaruh taruhan',
        close: 'Dilarang menaruh taruhan',
        locked: 'Penghitungan selesai',
        last_call: 'Taruhan terakhir',
        // arena_close: ""
        unsettled: 'Belum Selesai',
        settled: 'Selesai',
      },
      status: 'Status',
      // setting: '',
      settled_at: 'Waktu penghitungan',
      // settle_zero: '',
      side: 'Taruhan di',
      // skin: '',
      // skin_list: {
      //   default: '',
      //   brown: '',
      //   white: '',
      //   black: '',
      //   green: '',
      //   dark_and_light: '',
      // },
      slug: 'Ganjil',
      theme: 'Subjek resepsionis',
      // top_agent: '',
      total: 'Total',
      total_bet_amount: 'Total nominal taruhan',
      total_bet_count: 'Total taruhan',
      total_platform_profit: 'Total laba platform',
      total_rate_amount: 'Total nilai komisi',
      total_user_bet: 'Total yang bertaruh',
      total_valid_bet_amount: 'Total taruhan yang sah',
      type: 'Tipe',
      updated_at: 'Waktu pembaruan',
      // update_setting: '',
      usable_language: 'Bahasa yang tersedia',
      user: 'Pengguna',
      user_count: 'Jumlah anggota bertaruh',
      video_url: 'Alamat sumber video',
      // icp_video_url: "",
      valid_amount: 'Taruhan yang aktif',
      winner: 'Hasil tarung',
    },
    times: {
      unset: 'Belum di atur',
      today: 'Hari ini',
      yesterday: 'Kemarin',
      this_week: 'Minggu ini',
      last_week: 'Minggu lalu',
      this_month: 'Bulan ini',
      last_month: 'Bulan lalu',
      custom: 'Pengaturan sendiri',
      tooltip: 'Masa penyimpanan: 3 Bulan (tanggal hari ini hingga 100 hari)',
    },
    menu: {
      group: {
        player: 'Pemain',
        record: 'Laporan',
        manager: 'Mengelola',
        control: 'Platform kontrol',
        Agent: 'pengaturan agen',
        MasterAgent: 'Pengaturan pusat',
        ArenaModerator: 'Tarung ayam',
        // Pool: ''
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: 'Platform kontrol',
      CurrentArena: 'Taruhan sekarang',
      Players: 'pengaturan pemain',
      PlayerCredits: 'Catatan kuota pemain',
      BetLog: 'Catatan taruhan',
      GameResults: 'Hasil permainan',
      Order: 'Catatan transfer',
      AgentList: 'Daftar agen',
      AgentSource: 'Daftar total pusat agen',
      AdminAgents: 'pengaturan agen',
      AgentShow: 'pengaturan informasi',
      AgentRoles: 'pengaturan otoritas',
      AgentSubAccount: 'Admin',
      MasterAgentList: 'Daftar pusat agen',
      MasterAgentSource: 'Daftar total pusat agen',
      Merchants: 'admin bisnis',
      MerchantRoles: 'pengaturan peran bisnis',
      MerchantAdmin: 'pengaturan akun bisnis',
      MerchantIpList: 'Pengaturan Pembatasan IP',
      ChangePassword: 'Ubah kata sandi',
      GGR: 'Laporan GGR',
      Profile: 'Informasi diri sendiri',
      VideoSource: 'sumber video',
      Transfer: 'Pencarian transaksi pesanan',
      MonthlyProfit: 'Ringkasan laba bulanan',
      // DailyBettingStatistic: "",
      ExchangeRate: 'Pengaturan kurs',
      CreateAgent: 'Tambah agen',
      // PoolSetting: '',
      // MalayOddsSetting: '',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: 'Masukan {value}',
      select_value: 'Pilihlah {value}',
      must_be_at_4_characters: '{value} masukan minimal 4 karakter',
      must_be_at_6_characters: '{value} minimal masukkan 6 karakter',
      must_be_between_characters: '{value} harus antara {min} dan {max} karakter',
      must_be_between_number: '{value} harus antara {min} dan {max}',
      must_be_alphanumeric: '{value} wajib huruf alfabet dan angka',
      must_be_number: 'Harus berupa angka',
      must_be_number_value: '{value} harus berupa angka',
      two_no_match: 'pengisian di dua kolom tidak sama',
      failed_to_create: '{value} Gagal membuat baru',
      failed_to_edit: '{value} Logika gagal',
      success_to_create: '{value} Berhasil membuat baru',
      success_to_edit: '{value} Logika berhasil',
      success_to_change: '{value} Berhasil mengganti kata sandi',
      failed_to_update: '{value} Gagal merubah',
      decision_on_hold: 'Tekan tombol diatas untuk memberhentikan sementara taruhan dan transfer.',
      cancel_on_hold: 'Tekan tombol diatas untuk memulai pertarungan ayam',
      close_bet: 'Klik tombol di atas, fitur taruhan akan diberhentikan sementara',
      open_bet: 'Klik tombol di atas, fitur taruhan akan dibuka',
      mask_video: 'Klik tombol di atas, operator akan menyamarkan area video',
      cancel_mask_video: 'Klik tombol di atas, operator akan menutup penyamaran area video',
      delete: 'Hapus {value}?',
      choose_arena: 'Pilih pertarungan ayam',
      enter_fight_number: 'Masukan nomer tarungan',
      select_date: 'Harap pilih tanggal',
      select_one: 'Harap pilih minimal satu {value}',
      browser_not_supported:
        'Tidak mendukung browser ini. Silakan tekan tombol OK untuk membuka google chrome atau safari.',
      network_error_title: 'Kesalahan jaringan',
      network_error_info: 'Silakan cek jaringan Anda kemudian refresh halaman Kembali',
      must_be_greater_than_zero: 'Jumlah harus berupa angka dengan nilai 0 ke atas',
      must_be_greater_than_min: 'Jumlah maksimum harus lebih besar atau sama dengan jumlah minimum',
      incorrect_ip: 'Format IP salah',
      criteria_be_selected: 'Kriteria pencarian harus pilih 1',
      success_to_delete: '{value} berhasil dihapus',
      failed_to_delete: '{value} gagal dihapus',
      // failed_to_delete_with_reason: "",
      account_placeholder: 'harap masukan 4-16 huruf alfabet atau angka',
      password_placeholder: 'harap masukan 6-20 huruf alfabet atau angka atau simbol',
      confirm_password_placeholder: 'Harap masukan ulang kata sandi',
      date_type_hint:
        'Laporan pada "{dateType}" yang diberlakukan. Setiap siang pukul 10.00 (GMT+8) akan menghitung data kemarin, tidak termasuk data hari ini.',
      please_create_role: 'Sebelum membuat admin, harap buat peran',
      // max_length: '',
      // whole_url: '',
      setting_player_round_max: 'pengaturan taruhan nominal maksimal dan minimal per putaran',
      setting_player_bet_max: 'Pengaturan taruhan batas tertinggi pemain',
      // A_greater_tham_B: '',
      // reset_data: ''
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    },
  },
  AgentsPage: {
    master_account: 'Akun pusat',
    languageList: 'Daftar bahasa',
    create_master_agent: 'tambah jumlah agen',
    rate: 'Komisi (%)',
    rate_note: 'Jika komisi agen bawah lebih tinggi dari agen atas, sistem akan menurunkan komisi hingga sama rata.',
    max_with_value: 'Nilai maksimum: {value}',
    abbr: 'Singkatan',
    abbr_placeholder: 'Masukkan 1-6 karakter alfabet dan angka',
    edit_agent: 'agen pengeditan',
    // agent_credit_points_options: '',
  },
  AgentSubAccountPage: {
    authority: 'Otoritas',
    authority_list: {
      read: 'Baca',
      editor: 'Edit',
    },
    create_sub_account: 'Menambah admin',
  },
  BetLogPage: {
    win_lose: 'kalah menang',
    bet_return: 'Kembalian',
    is_settled: 'Apakah sudah dihitung',
    device: 'Perangkat',
    amount_type: 'Tipe nilai',
    amount_range: 'Kisaran nilai',
  },
  ChangePasswordPage: {
    current_password: 'Kata sandi yang sekarang',
    current_password_placeholder: 'Masukan kata sandi yang sekarang',
    new_password: 'Kata sandi baru',
  },
  ArenaModeratorPage: {
    video_rate: 'Komisi sumber video',
    online_users: 'Jumlah online',
  },
  ControlPanelPage: {
    arena_on: 'Buka',
    arena_off: 'tutup',
    no_announce: 'Belum diumumkan, tekan tombol edit untuk menambah',
    edit_announce: 'Mengedit pengumuman',
    open_arena_title: 'Memulai bisnis pertarungan ayam?',
    close_arena_title: 'Yakin ingin menutup pertandingan hari ini (semua pertarungan akan di akhiri)',
    start_fight: 'Waktu mulai',
    start_time: 'Memulai bisnis pertarungan ayam',
    open_betting: 'menerima taruhan?',
    close_betting: 'Tutup taruhan?',
    re_declare_title: 'Mengumumkan ulang pemenang?',
    re_declare_content: 'Mengumumkan ulang pemenang,pertandingan # {id} pemenangannya',
    lock_earing_title: 'Bayar?',
    lock_earing_content: 'Pembayaran, pertarungan # {id}',
    click_again_to: 'Klik untuk',
    file_upload: 'Unggah file',
    arena_cover: 'Gambar pintu masuk permainan',
    mask_file: 'Gambar samar',
    schedule: 'Jadwal pertandingan',
    // edit_provider_url: "",
    img_path: 'Path file',
    below_px_value: 'Di bawah {value}px',
    img_format: 'Format',
    img_recommended_size: 'Ukuran yang disarankan',
    file_size: 'Ukuran file',
    image: 'Gambar',
    file_no_selected: 'Tidak ada file yang dipilih',
    no_image: 'Tidak ada gambar',
    // edit_video_url: "",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: 'Harap masukan nomer pertarungan',
      max_image_size: 'Ukuran gambar maksimum adalah {value}kb',
      error_image_type: 'Format gambar salah, hanya terima: jpg, jpeg, png',
      delete_image_placeholder: 'Anda yakin ingin menghapus {value}?',
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    },
  },
  CurrentArenaPage: {
    count: 'Jumlah',
    unsettlement: 'Belum dihitung',
    fight_action: 'Operasi pertandingan',
  },
  GameResultsPage: {
    open_bet_at: 'Waktu penerima taruhan',
    close_bet_at: 'Waktu taruhan selesai',
    declare_at: 'Waktu pengumuman hasil',
    reload_at: 'Waktu pengaturan',
    cancel_at: 'Waktu pembatalan',
    total_rate: 'Total komis',
    total_meron: 'Naga-Total taruhan',
    total_wala: 'Feniks-Total taruhan',
    total_draw: 'Seri-Total taruhan',
    status_list: {
      initial: 'Awal',
      betting: 'Taruhan berlangsung',
      fighting: 'Pertandingan berlangsung',
      finished: 'Sedang penghitungan',
      reloaded: 'Penentuan ulang',
      earn_locked: 'Pembayaran',
      last_call: 'Taruhan terakhir',
      cancel: 'Batal',
    },
    time: 'Waktu',
    // reason: "",
    cancel_reasons: {
      // '3000': "",
      // '3001': "",
      // canceled_manual: "",
      // canceled_ws: ""
    },
    open_round: 'Waktu mulai',
  },
  LoginPage: {
    log_in: 'Masuk',
    otp: 'Sandi dinamis',
    message: {
      success: 'Masuk berhasil',
      failed: 'Akun atau kata sandi salah',
    },
  },
  MerchantsPage: {
    admin: 'Pengaturan admin',
    role: 'Pengaruran peran',
    create_merchant: 'Membuat baru nomer bisnis',
    name: 'Nama bisnis',
    message: {
      // master_agent_only_select_one: ""
    },
  },
  MerchantRolesPage: {
    // current_arena: '',
    can_permission_pages: 'Halaman yang dapat di pakai',
    use_account: 'pakai akun',
    create_role: 'Membuat peran',
    edit_role: 'Mengedit peran',
    role_name: 'Nama Peran',
    color: 'Warna',
    can_use_pages: 'Akun yang dapat di pakai',
    arena_on_off: 'Tombol pertarungan',
    fight_control: 'Pengaturan urutan pertarungan',
    lock_earning: 'Pembayaran',
    announcement: 'Umumkan',
    decision_on_hold: 'Memberhentikan pertarungan',
    monitor: 'Pantau',
    no_bets_allowed: 'Tidak boleh taruhan',
    mask_video: 'Video samar',
    read: 'Baca',
    update: 'Memperbaharui',
    create: 'Bangun',
    delete: 'Hapus',
    edit: 'Edit',
    dashboard_card: 'Tunjukkan total nilai',
    confirm: 'Konfirmasi',
    approve: 'Menyetujui',
    arena_cover: 'Gambar pintu masuk permainan',
    mask_file: 'Gambar samar',
    schedule: 'Jadwal pertandingan',
    export: 'Ekspor',
    // edit_provider_url: "",
    // display_rate: "",
    // view_schedule: '',
    // view_log: '',
    account_to_use: 'Ada akun yang sedang memakai peran ini',
    // risk_edit: '',
    monitor_video: 'Pantau video',
  },
  MerchantAdminPage: {
    edit_manager: 'Mengedit admin',
    create_manager: 'Membuat admin',
  },
  MerchantIpPage: {
    master_agent: 'Pusat atau agen',
    create_ip: 'Tambahkan IP',
  },
  DailyBettingStatisticPage: {
    // yesterday_percentage: "",
    // last_week_percentage: "",
    player_count: 'Jumlah anggota bertaruh',
    // ave_per_fight_percentage: "",
    // last_week_ave_per_fight_percentage: ""
  },
  OrderPage: {
    after_balance: 'Saldo setelah transfer',
  },
  PlayersPage: {
    balance: 'Sisa',
    fee: 'Biaya adiministrasi',
    current_sign_in_ip: 'IP masuk',
    total_deposit: 'Total penyimpanan',
    total_withdraw: 'Total pengambilan',
    total_win_lose: 'Total kemenangan & kekalahan',
  },
  PlayerCreditsPage: {
    id: 'No transaksi',
    type: {
      deposit: 'Uang masuk',
      withdraw: 'Uang keluar',
      settle: 'Menang',
      cancel: 'Batal',
      bet: 'Membuat taruhan',
    },
    before_amount: 'Nominal sebelum operasi',
    after_amount: 'Nominal setelah operasi',
  },
  ProfilePage: {
    // setting_round_draw_max: "Pengaturan taruhan maksimal per putaran",
    setting_round_draw_max_part1: 'Pengaturan setiap putaran',
    setting_round_draw_max_part2: 'taruhan nominal maksimal',
    player_limit: 'taruhan nominal maksimal pemain',
    setting_player_max: 'pengaturan taruhan nominal maksimal dan minimal per putaran',
    basic_info_title: 'Informasi dasar',
    basic_info_text: 'informasi dasar agen anda',
    // integration_info: '',
    // auth: '',
    // secret: '',
    // api_doc_url: '',
    // api_url: '',
    // video_traffic: '',
    // remain_video_data: '',
    // update_video_data: '',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
    // warning_no_enough_point: '',
    // please_decrease_own_points_to_zero: '',
    // please_remove_all_designated_agents: '',
    // please_confirm_all_agents_settled: '',
  },
  VideoSourcePage: {
    source: 'sumber video',
    agent_source: 'Sumber video agen',
    accessable_merchants: 'bisnis yang dapat dipakai',
  },
  TransferPage: {
    slug: 'Nomor transaksi',
    data: 'Data',
    res: 'Respon',
    round_id: 'Putaran',
    setting_master_id: 'Pengaturan',
    num: 'Angka ganjil',
    resend: 'Kirim ulang',
    bet_num: 'Angka ganjil',
    resend_note: 'Catatan',
    resend_mark: 'Tanda',
    message: {
      success_to_resend: 'Pengiriman ulang berhasil, No:{value}',
      fail_to_resend: 'Pengiriman ulang gagal, No:{value}',
      success_to_remark: 'Catatan berhasil, No:{value}',
      fail_to_remark: 'Catatan gagal, No:{value}',
    },
  },
  ExchangeRatePage: {
    currency: 'ID mata uang',
    currency_name: 'Nama mata uang',
    last_approved_rate: 'Kurs sebelumnya',
    today_rate: 'Kurs hari ini',
    ref_rate: 'Referensi kurs',
    ref_rate_time: 'Referensi waktu kurs',
    tbd_confirm_rate: 'Konfirmasi kurs',
    tbd_modify_rate: 'Ubah kurs',
    record: 'Log transaksi',
    current_time: 'Waktu saat ini',
    last_approved_time: 'Waktu persetujuan terakhir',
    base_currency: 'Mata uang standar',
    fetch_exchange_rate: 'Dapatkan kurs',
    approve_rate: 'Kurs yang disetujui',
    rate_warning: '*Perhatian: Kurs akan langsung berlaku setelah disetujui dan dikonfirmasi!',
    log: 'Riwayat perubahan kurs',
    confirmed_by: 'Dikonfirmasi oleh',
    confirmed_time: 'Waktu konfirmasi',
    approved_by: 'Disetujui oleh',
    approved_time: 'Waktu persetujuan',
    exchange_rate: 'Kurs',
  },
  CreateAgentPage: {
    // parent_agent: '',
    // environment: '',
    // staging: '',
    // production: '',
    // brand: '',
    // trade_mode: '',
    // transfer_wallet: '',
    // single_wallet: '',
    // options: '',
    // level_sub_agent_with_value: '',
    // delete_agent_with_value: '',
    // add_agent_with_value: '',
    // agent_info: '',
    // designated_agent: '',
    message: {
      // select_master_first: '',
      // agent_same_master: '',
      // please_refresh_cache: '',
      // log_saved_time: '',
      // success_refresh_cache: '',
      // fail_refresh_cache: '',
    },
  },
  PoolSettingPage: {
    // pool_name: '',
    // odds_refresh_frequency: '',
    // last_call_percentage: '',
    // close_bet_percentage: '',
    // per_bet: '',
    // per_round: '',
    // risk_threshold: '',
    // current_schedule: '',
    // new_schedule: '',
    // update_schedule: '',
    // schedule: '',
    // view: '',
    // start_time: '',
    // end_time: '',
    // pool_configuration_options: '',
    // expect_date: '',
    // expect_time: '',
    // add_new_pool_configuration: '',
    // exceed_time: '',
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: '',
    // change_agent: '',
    // view_complete_record: '',
    // point_usage: '',
    // points_deduction: '',
    // positive_numbers: '',
    // negative_numbers: '',
    // points_addition: '',
  },
}
