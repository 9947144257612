<template>
  <div class="frame agent-points-record-page">
    <a-page-header
      :title="$t('global.menu.AgentPointsRecord')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form" :rules="rules">
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :showCurrency="true"
                      :showMaster="true"
                      :showAgent="false"
                      :currencyRequired="true"
                      :masterRequired="false" />
            <a-form-model-item ref="agent" :label="$t('global.common.agent')" prop="agent">
              <a-select 
                v-model="form.agent"
                show-search
                :show-arrow="true"
                :filter-option="filterOption"
              >
                <a-select-option v-for="typeItem in prepaidFundsAgentList" :value="typeItem.id" :key="typeItem.id">{{ typeItem.account }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="player_account" :label="$t('global.common.player_account')" prop="player_account">
              <a-input
                v-model="form.player_account"
              />
            </a-form-model-item>
            <a-form-model-item ref="slug" :label="$t('global.common.slug')" prop="slug">
              <a-input
                v-model="form.slug"
              />
            </a-form-model-item>
            <a-form-model-item ref="type" :label="$t('AgentCreditPointsPage.change_type')" prop="type">
              <a-select v-model="form.type" mode="multiple" :show-arror="true" :disabled="formViewCompleteRecord">
                <a-select-option v-for="typeItem in changeTypeList" :value="typeItem.value" :key="typeItem.value">{{
                  typeItem.label === 'all'
                    ? $t(`global.common.${typeItem.label}`)
                    : $t(`AgentCreditPointsPage.change_type_list.${typeItem.label}`)
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="date_type" :label="$t('global.common.date_type')" prop="date_type">
              <a-select v-model="form.date_type">
                <a-select-option v-for="typeItem in dateTypeList" :value="typeItem" :key="typeItem">{{
                  $t(`global.common.date_type_list.${typeItem}`)
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="view_complete_record" prop="view_complete_record" class="search-checkbox">
              <a-checkbox-group v-model="form.view_complete_record">
                <a-checkbox :value="1">{{ $t('AgentCreditPointsPage.view_complete_record') }}</a-checkbox>
              </a-checkbox-group>
            </a-form-model-item>
            <!--
            <a-form-model-item :label="$t('global.merchant.id')" prop="merchant">
              <a-select v-model="form.merchant" placeholder="please select merchant">
                <a-select-option value="shanghai">
                  Merchant 1
                </a-select-option>
                <a-select-option value="beijing">
                  Merchant 2
                </a-select-option>
              </a-select>
            </a-form-model-item>-->
          </div>
          <div class="search-group">
            <Datetime :input.sync="form.date"
                      :periodDefault.sync="periodDefault"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control table-controls">
            <div class="table-placeholder">
              <div class="table-placeholder-group">
                <span> {{ $t('AgentCreditPointsPage.point_usage') }}： </span>
                <span>{{ totalPrepaidAmount | formatNumberWithPoint }}</span>
              </div>
              <a-tooltip>
                <template slot="title">
                  <div>
                    <span
                      >{{ $t('AgentCreditPointsPage.positive_numbers') }} =
                      {{ $t('AgentCreditPointsPage.points_addition') }}</span
                    >
                    <span>；</span>
                    <span
                      >{{ $t('AgentCreditPointsPage.negative_numbers') }} =
                      {{ $t('AgentCreditPointsPage.points_deduction') }}</span
                    >
                  </div>
                </template>
                <a-icon type="question-circle" class="question-circle" />
              </a-tooltip>
            </div>
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    {{ item.singleI18n ? $t(`AgentCreditPointsPage.${item.slots.title}`) : $t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="log"
            :pagination="pagination"
            :loading="loading"
            :row-key="orders => orders.slug"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <span :slot="column.slots.title" :key='column.slots.title'>{{ column.singleI18n ? $t(`AgentCreditPointsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</span>
            </template>
            <template slot="type" slot-scope="type">
              <span>{{ $t(`AgentCreditPointsPage.change_type_list.${type}`) }}</span>
            </template>
            <template slot="amount" slot-scope="amount">
              <div class="table-amount" :class="{'is-positive' : numberType(amount)}">{{ amount | formatNumberWithPoint }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimesToUTC8 }}</div>
            </template>
            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getAgentPointsRecord, getPrepaidFundsAgent } from '@/../api';
import Datetime from '@/../src/components/datetime_picker';
import Currency from '@/../src/components/currency_selection';
import qs from 'query-string'

const columns = [
  {
    slots: { title: 'master' },
    dataIndex: 'master',
    filterType: true,
  },
  {
    slots: { title: 'agent' },
    dataIndex: 'agent',
    filterType: true,
  },
  {
    slots: { title: 'player_account' },
    dataIndex: 'player',
    scopedSlots: { customRender: 'formatString' },
    filterType: true,
  },
  {
    slots: { title: 'change_type' },
    dataIndex: 'type',
    align: 'center',
    scopedSlots: { customRender: 'type' },
    // customCell:(text) => {
    //   return {
    //     class: 'source-type type-'+ text.type,
    //   };
    // },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'change_agent' },
    dataIndex: 'change_agent',
    scopedSlots: { customRender: 'formatString' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'points' },
    dataIndex: 'amount',
    align: 'right',
    scopedSlots: { customRender: 'amount' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'points_before_change' },
    dataIndex: 'before_balance',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'points_after_change' },
    dataIndex: 'after_balance',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'slug' },
    dataIndex: 'slug',
    filterType: true,
  },
  {
    slots: { title: 'settled_at' },
    dataIndex: 'settled_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
];

export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      expand: false,
      loading: false,
      columns,
      form: {
        date: [new Date(), new Date()],
        slug: '',
        type: [],
        agent: null,
        currency: '',
        master: null,
        player_account: '',
        view_complete_record: [],
        date_type: 'SettleAt',
      },
      changeTypeList: [
        {
          value: 'all',
          label: 'all'
        },
        {
          value: 'order_bet',
          label: 'order_bet'
        },
        {
          value: 'order_settle',
          label: 'order_settle'
        },
        {
          value: 'order_cancel',
          label: 'order_cancel'
        },
        {
          value: 'increase',
          label: 'increase'
        },
        {
          value: 'deduct',
          label: 'deduct'
        },
      ],
      periodDefault: 'today',
      log: [],
      rules: {
        agent: [{ required: true, trigger: 'submit' }],
      },
      prepaidFundsAgentList: [],
      dateTypeList: ['SettleAt'],
      totalPrepaidAmount: 0
    };
  },
  mounted() {
    // if (this.$route.query.slug) {
    //   this.periodDefault = 'unset';
    //   setTimeout(() => {
    //     this.onSearch();
    //   }, 100);
    // }
    // this.onSearch();
  },
  computed: {
    ...mapState({currentProfile: state => state.auth.currentProfile}),
    formCurrencyAndMaster() {
      return {
        currency: this.form.currency,
        master: this.form.master,
      };
    },
    formViewCompleteRecord() {
      return this.form.view_complete_record.length > 0
    },
  },
  watch: {
    formCurrencyAndMaster: {
      async handler(newValue) {
        const { currency, master } = newValue;
        if(!currency && !master) return;
        this.form.agent = null;
        const form = {}
        if (master) {
          form.master_ids = [master];
        }else{
          form.currency = currency;
        }
        await this.getAgentList(form);
      },
    },
    formViewCompleteRecord: {
      handler(newValue) {
        if (newValue) {
          this.form.type = [this.changeTypeList[0].value]
        } else {
          this.form.type = []
        }
      },
    },
  },
  methods: {
    handleTableChange(pagination) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      const { current, pageSize } = this.pagination;
      const { date, player_account, slug, type, agent, master, view_complete_record, date_type } = this.form
      const formData = {
        player_account, 
        slug, 
        type, 
        results: pageSize, 
        page: current, 
        agent_id: agent,
        master_id: master, 
        start_time: date[0], 
        end_time: date[1],
        view_complete_record: view_complete_record.length > 0 ? 1 : 0,
        date_type,
      }
      getAgentPointsRecord(formData).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.total || 0;
        this.log = data.log;
        this.totalPrepaidAmount = data.total_prepaid_amount;
        this.pagination = pagination;
      }).catch((err)=>{
        this.$message.error(this.$t('global.message.failed_to_fetch', {value: this.$t('global.menu.AgentPointsRecord')}));
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.pagination.current = 1;
          this.fetchData();
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      // this.form.slug = '';
      this.periodDefault = 'today';
      // this.onSearch();
      // setTimeout(() => {
      //   this.onSearch();
      // }, 100);
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    numberType(arg) {
      if (typeof arg == "string") {
        return !!(arg.match(/^\d+((\.\d+){0,})?$/) && parseFloat(arg) > 0)
      } else if (typeof arg == "number") {
        return !isNaN(arg) && arg> 0;
      }
      return false;
    },
    async getAgentList(form){
      const query = qs.stringify(form, {arrayFormat: 'bracket'});
      await getPrepaidFundsAgent(query)
        .then(({ data }) => {
          this.prepaidFundsAgentList = data.agents;
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
